.privacy-container{
    display: block;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    

    .privacy-wrapper{
        display: block;
        max-width: 712px;
        padding: 10px;
        word-break: initial;
        // word-wrap: break-word;
        // flex-wrap: wrap;

        ol li{
            margin-bottom: 5px;
        }
    }
}