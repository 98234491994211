.page-container{
    position: relative;
    display: block;
    background-color: white !important;
    .navbar{
        width: 100%;
        padding: 10px;
        display: block;
        position: sticky;
        top: 0px;
        left: 0px;

        .container{
            display: flex;
            margin-left: auto;
            margin-right: auto;
            justify-content: space-between;
            align-items: center;
            max-width: 80%;

            .nav-menu{
                display: flex;
                // .nav-item{
                //     margin-right: ;
                // }
            }
        }
    }

    .page-wrapper-body{
        .mypage-container{
            .page-banner{
                width: 100%;
                max-height: 600px;
                position: relative;
                padding: 10px;
                margin-bottom: 50px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                img{
                    width: 100%;
                    max-height: 600px;
                    object-fit: cover;
                    border-radius: 20px;
                }
                .text-title{
                    position: absolute;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    
                    .title{
                        font-size: 5vh;
                        font-weight: 500;
                        -webkit-text-stroke: 2px rgb(255, 255, 255);
                    }
                    .sub-title{
                        font-size: 3vw;
                        font-weight: 400;
                        -webkit-text-stroke: 1px rgb(114, 90, 90);
                    }
                }
                .property-box{
                    padding-left: 20px;
                    padding-right: 20px;
                    // border: 1px solid #000;
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    .property-wrapper{
                        overflow-x: auto;
                        overflow-y: hidden;
                        -ms-overflow-style: none;
                        scrollbar-width: none;
                        padding: 20px;
                        background-color: #ffffff;
                        display: flex;
                        border-radius: 10px;

                        &::-webkit-scrollbar{
                            display: none;
                        }

                        .btn{
                            // overflow: hidden;
                            text-overflow: ellipsis;
                            height: 2.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
    
            .page-property{
                margin-bottom: 50px;
                max-width: 1024px;
                margin-left: auto;
                margin-right: auto;
                padding: 10px;
    
                .property-item{
                    position: relative;
                    height: 200px; 
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
    
                    .label-1{
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        font-size: large;
                        font-weight: 400;
                    }
    
                    .icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 40px;
                        height: 40px;
                        background-color: #ffffff;
                        border-radius: 50%;
                    }
    
                    .label-2{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                    }
    
                    .text-number{
                        font-weight: 500;
                        font-size: 5em;
                    }
                }
            }
    
            .page-maps{
                margin-bottom: 50px;
                max-width: 1024px;
                margin-left: auto;
                margin-right: auto;
                height: 250px;
                position: relative;
    
                .map_pic{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 15px;
                }
    
                .container{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background-color: black;
                    opacity: 0.8;
                    border-radius: 15px;
                    
                }
                .text-container{
                    position: absolute;
                    top: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    padding: 10px;
                }
            }
    
            .page-body-1{
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
                // border: 1px solid #000;
                p.title{
                    font-size: 4vw;
                    font-weight: 400;
                    text-align: center;
                    .icon{
                        font-size: .7em;
                    }
                }
                .card-tag{
                    display: block;
                    position: relative;
                    
                    .tag{
                        display: block;
                        position: absolute;
                        top: 10px;
            
                        .tag-1{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
            
                        .tag-2{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .tag-3{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
    
                .card-body{
                    .body-1{
                        .card-title{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 1.5em;
                        }
                        .card-text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 4.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
    
            .page-body-2{
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
    
                p.title{
                    font-size: 4vw;
                    font-weight: 400;
                    text-align: center;
                }
    
                .card-tag{
                    display: block;
                    position: relative;
                    
                    .tag{
                        display: block;
                        position: absolute;
                        top: 10px;
            
                        .tag-1{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
            
                        .tag-2{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .tag-3{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
    
                .card-body{
                    .body-1{
                        .card-title{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 1.5em;
                        }
                        .card-text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 4.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
    
            .page-body-3{
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
    
                p.title{
                    font-size: 4vw;
                    font-weight: 400;
                    text-align: center;
                }
    
                .header{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
            
                    .user-img{
                        width: 64px;
                        height: 64px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
            
                    .oversize{
                        font-size: 1.8rem;
                        font-weight: 500;
                        color: $color-default;
                    }
            
                    .text-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-default;
                        color: white;
                        width: 90%;
                        border-radius: 50px;
                        // width: fit-content;
            
                        .icon{
                            margin-left: -20px;
                        }
                        .label{
                            text-align: center;
                            line-height: 1.5em;
            
                            .label-1{
                                font-size: 1.3em;
                                font-weight: 400;
                            }
                        }
                    }

                    
            
                    .date{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                }
                .text-description{
                    .text-detail{
                        font-size: 16px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 4.5em;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
    
            .page-body-4{
                display: flex;
                position: relative;
                padding: 3vw;
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
                background-color: #D5DBDB;
                border-radius: 20px;
                height: 100%;
    
                .box-1{
                    padding: 20px;
                    margin-right: 20px;
                    width: 40%;
                    // position: relative;
                    .subBox{
                        width: 300px;
                        position: relative;
                        .decorate-1{
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            width: 60px;
                            height: 60px;
                            background-color: rgb(75, 75, 75);
                            border-radius: 10px;
                            z-index: 0;
                        }
                        .decorate-2{
                            position: absolute;
                            bottom: -5px;
                            right: -10px;
                            width: 100px;
                            height: 100px;
                            background-color: rgb(75, 75, 75);
                            border-radius: 10px;
                            z-index: 0;
                        }
        
                        .profile-img{
                            position: relative;
                            width: 100%;
                            border-radius: 10px;
                            z-index: 2;
        
                            // &::after{
                            //     display: block;
                            //     content: '';
                            //     position: absolute;
                            //     top: -10px;
                            //     left: -10px;
                            //     width: 40px;
                            //     height: 40px;
                            //     background-color: slateblue;
                            //     border-radius: 10px;
                            //     z-index: 1;
                            // }
                        }
                    }
    
                    .subBox-2{
                        display: flex;
                        // position: relative;
                        width: 100%;
                    }
                    
                }
                .box-2{
                    width: 60%;
                    margin-left: 20px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
    
                    .subBox{
                        width: 100%;
                    }
                }
            }
    
            .page-body-5{
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
    
                p.title{
                    font-size: 4vw;
                    font-weight: 400;
                    text-align: center;
                    .icon{
                        font-size: .7em;
                    }
                }
    
                .tiktok-container{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(1, 1fr);
                    gap: 10px;
                    margin-bottom: 20px;
                    background-color: white;
                    border-radius: 10px;
                    .tiktok_item{
                        &.tiktok_item-1{
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.tiktok_item-2{
                            grid-column-start: 2;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.tiktok_item-3{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.tiktok_item-4{
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                        &.tiktok_item-5{
                            grid-column-start: 2;
                            grid-column-end: 3;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                        &.tiktok_item-6{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                    }
                }
            }
    
            .page-body-6{
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
    
                p.title{
                    color: $color-default;
                    font-size: 4vw;
                    font-weight: 400;
                    text-align: center;
                    .icon{
                        font-size: .7em;
                    }
                }
    
                .image-container{
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: repeat(2, 12vw);
                    gap: 10px;
                    margin-bottom: 20px;
    
                    .gallery__item{
                        position: relative;
                        cursor: pointer;
                        z-index: 0;
                        .backdrop{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 1;
                            padding: 20px 10px;
                            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    
                            .text{
                                color: #fff;
                                font-weight: 500;
                            }
                        }
                        .gallery__img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        &.gallery_item-1{
                            grid-column-start: 1;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 3;
                        }
                        &.gallery_item-2{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.gallery_item-3{
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.gallery_item-4{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                        &.gallery_item-5{
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                    }
                }
            }
        }
        .mymap-container{
            .filter-container{
                overflow-x: auto;
                padding: 10px;
                width: 100%;

                .nav{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    
                    .nav-item{
                        margin-right: 10px;
                        margin-left: 10px;
                        padding: 10px 15px;
                        background-color: transparent;
                        border-radius: 10px;
                        cursor: pointer;

                        &.active{
                            background-color: var(--color-custom);
                        }

                        .nav-link{
                            color: black;
                            &.active{
                                color: white;
                            }
                        }
                    }
                }
            }
            .mapWrapper{
                position: relative;
                width: 100%;
                height: 90vh;

                .gm-style-iw{
                    width: 330px !important;
                    .image-gallery{
                        height: 200px;
                        border-radius: 10px;
                        .image-gallery-left-nav,
                        .image-gallery-right-nav{
                            padding: 0px 8px !important;
                            .gallery-icon-size{
                                font-size: 20px;
                            }
                        }
                        .image-gallery-image{
                            object-fit: cover;
                            width: 300px;
                            height: 200px;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                    }
                }
            }
            .buttom-container{
                display: flex;
                justify-content: center;
                position: fixed;
                bottom: 10px;
                left: 0;
                width: 100%;
                z-index: 10;

                .btn-buttom{
                    cursor: pointer;
                    padding: 10px;
                    background-color: inherit;
                    border: none;
                    border-radius: 50px;
                    color: #fff;
                }
            }
        }
        .mylist-container{
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 10px;

            .filter-container{
                overflow-x: auto;
                padding: 10px;
                width: 100%;
                scrollbar-color: var(--color-custom) #bada55;

                .nav{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;

                    .nav-item{
                        margin-right: 10px;
                        margin-left: 10px;
                        padding: 10px 15px;
                        background-color: transparent;
                        border-radius: 10px;
                        cursor: pointer;

                        &.active{
                            background-color: var(--color-custom);
                        }

                        .nav-link{
                            color: black;
                            &.active{
                                color: white;
                            }
                        }
                    }
                }
            }

            .card{
                display: block;
                
                .card-body{
                    .body-1{
                        .card-title{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 1.5em;
                        }
                        .card-text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 4.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
                .card-tag{
                    display: block;
                    position: relative;
                    
                    .tag{
                        display: block;
                        position: absolute;
                        top: 10px;
    
                        .tag-1{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
    
                        .tag-2{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .tag-3{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }

                    .btn-share{
                        position: absolute;
                        right: 10px;
                        top: 10px;
                        z-index: 5;
                        .icon{
                            font-size: 18px;
                        }
                    }
                }
            }
            .buttom-container{
                display: flex;
                justify-content: center;
                position: fixed;
                bottom: 10px;
                left: 0;
                width: 100%;
                z-index: 10;

                .btn-buttom{
                    cursor: pointer;
                    padding: 10px;
                    background-color: inherit;
                    border: none;
                    border-radius: 50px;
                    color: #fff;
                }
            }

            .pagination-bar-custom{
                .pagination-item.selected {
                    background: var(--color-custom);
                }
                
            }
        }
        .myestate-container{
            display: block;
            max-width: 1024px;
            margin-top: 50px;
            margin-left: auto;
            margin-right: auto;

            .myestate-header{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;
        
                .container-like{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }
            }
            .myestate-image{
                width: 100%;
                height: 100%;
            }
            .myestate-map{
                width: 100%;
                .header{
                    font-size: x-large;
                    font-weight: 400;
                    margin-bottom: 20px;
                }
                .mapWrapper{
                    width: 100%;
                    height: 60vh;
                    border-radius: 10px;
                }
        
                .gm-style-iw{
                    width: 330px !important;
                    .image-gallery{
                        height: 200px;
                        border-radius: 10px;
                        .item-img{
                            width: 300px;
                            height: 200px;
                            border-radius: 10px;
                            object-fit: contain;
                            overflow: hidden;
                        }
                        .image-gallery-left-nav,
                        .image-gallery-right-nav{
                            padding: 0px 8px !important;
                            .gallery-icon-size{
                                font-size: 20px;
                            }
                        }
                    }
                }
        
                .gm-style-iw-d{
                    overflow-x: hidden !important;
                }
            }
            .myestate-info{
                display: flex;
                flex-direction: row;
                position: relative;
                .estate-info-container-1{
                    display: flex;
                    flex-direction: column;
                    width: 60%;
                    padding: 0px 20px 20px 0px;
        
                    .header-container{
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        border-bottom: 0.5px solid $color-gray;
                        padding-bottom: 10px;
                        .header{
                            font-size: x-large;
                            font-weight: 400;
                        }
                        .saler-quick{
                            width: fit-content;
                            .description{
                                padding: 5px;
                                font-size: 14px;
                                color: $color-danger;
                                border: 0.5px solid $color-danger;
                                border-radius: 10px;
                            }
                        }
                    }
        
                    .showinfo{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding: 30px;
                        border-bottom: 0.5px solid $color-gray;
                    }
        
                    .co-agent{
                        padding: 10px;
                        background-color: rgb(210, 229, 252);
                        color: rgb(52, 94, 151);
                        border-radius: 10px;
                    }
        
                    .saler-info{
                        display: flex;
                        flex-direction: row;
                        padding: 30px 0px;
                        border-bottom: 0.5px solid $color-gray;
                        .saler-img{
                            width: 64px;
                            height: 64px;
                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: 50%;
                            }
                        }
                        .saler-detail{
                            .saler-body > p{
                                line-height: 2em;
                                font-weight: 400;
                            }
                            .saler-status{
                                width: fit-content;
                                .description{
                                    padding: 5px;
                                    font-size: 14px;
                                    color: $color-default;
                                    border: 0.5px solid $color-default;
                                    border-radius: 10px;
                                }
                            }
                            .hideinfo{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
        
                    .estate-description{
                        width: 100%;
                        .col{
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 20px;
                            .title{
                                font-size: large;
                                font-weight: 400;
                            }
                            .text{
                                padding: 10px 5px;
                                border-bottom: 0.5px solid $color-gray;
                            }
                        }
                        .estate-cityplan{
                            display: flex;
                            flex-direction: column;
                            .title{
                                font-size: 20px;
                                font-weight: 400;
                                margin-bottom: 10px;
                            }
                        }
                        .estate-highlight{
                            width: 70%;
                            // max-width: 70%;
                            display: flex;
                            flex-direction: column;
                            .title{
                                font-size: 20px;
                                font-weight: 400;
                                margin-bottom: 10px;
                            }
                            .text{
                                padding: 10px;
                                border: 0.5px solid $color-gray;
                                border-radius: 10px;
                                resize: none;
                            }
                        }
                    } 
                }
                .estate-info-container-2{
                    display: flex;
                    flex-direction: column;
                    width: 40%;
                    padding: 0px 20px 20px 20px;
                    .detail-wrapper{
                        border: 0.5px solid $color-gray;
                        border-radius: 10px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .page-container{
        .navbar{
            .container{
                flex-direction: column;

                .nav-menu{
                    padding-top: 20px;
                }
            }
        }

        .page-wrapper-body{
            .mymap-container{
                .filter-container{
                    .nav{
                        justify-content: unset;
                    }
                }
            }
            .mylist-container{
                .filter-container{
                    margin-bottom: 20px;
                    .nav{
                        justify-content: unset;
                    }
                }
            }
            .mypage-container{
                .page-banner{
                    .property-box{
                        bottom: 20px;
                    }
                }
                .page-body-4{
                    flex-direction: column;
                    .box-1{
                        width: 100%;
                        margin-right: 0px;
                        .subBox{
                            width: 100%;
                        }
                    }
                    .box-2{
                        margin-left: 0px;
                        width: 100%;
                        padding: 10px;
                    }
                }
                .page-body-5{
                    .tiktok-container{
                        grid-template-columns: repeat(1, 1fr) !important;
                        grid-template-rows: repeat(3, 1fr) !important;
    
                        .tiktok_item{
                            &.tiktok_item-1{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 1 !important;
                                grid-row-end: 2 !important;
                            }
                            &.tiktok_item-2{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 2 !important;
                                grid-row-end: 3 !important;
                            }
                            &.tiktok_item-3{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 3 !important;
                                grid-row-end: 4 !important;
                            }
                        }
                    }
                }
                .page-body-6{
                    padding: 10px;
                    .image-container{
                        grid-template-columns: repeat(1, 1fr) !important;
                        grid-template-rows: repeat(5, 1fr) !important;
                        .gallery__item{
                            &.gallery_item-1{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 1 !important;
                                grid-row-end: 2 !important;
                            }
                            &.gallery_item-2{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 2 !important;
                                grid-row-end: 3 !important;
                            }
                            &.gallery_item-3{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 3 !important;
                                grid-row-end: 4 !important;
                            }
                            &.gallery_item-4{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 4 !important;
                                grid-row-end: 5 !important;
                            }
                            &.gallery_item-5{
                                grid-column-start: 1 !important;
                                grid-column-end: 2 !important;
                                grid-row-start: 5 !important;
                                grid-row-end: 6 !important;
                            }
                        }
                    }
                }
            }
            .myestate-container{
                padding: 10px;
                .myestate-info{
                    flex-direction: column;

                    .estate-info-container-1{
                        width: 100%;
                        .estate-description{
                            .estate-highlight{
                                width: 100%;
                            }
                        }
                    }
                    .estate-info-container-2{
                        width: 100%;
                    }
                }
            }
        }
    }
}