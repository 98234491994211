.payment-containner{
    display: block;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    color: $color-dark;

    .payment-header{
        font-weight: 500;
        font-size: 30px;
        display: flex;
        align-items: baseline;
    }
    .payment-body{
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .payment-body-wrapper-1{
            width: 50%;
            // height: 300px;
            padding: 10px;
            border: 1px solid #c6c6c6;
            margin-right: 5px;
            border-radius: 10px;

            
        }
        .payment-body-wrapper-2{
            width: 35%;
            // height: 300px;
            padding: 10px;
            border: 1px solid #c6c6c6;
            margin-left: 5px;
            border-radius: 10px;

            .payment-service{
                padding: 10px;
                border-radius: 10px;

                .wrapper{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    color: rgb(22, 111, 213);
                }
            }
        }
        
    }
}

.btn-payment{
    text-align: center;
    border: 0.5px solid rgb(175, 175, 175);
    background-color: white;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
    cursor: pointer;
}
.btn-payment.choose{
    text-align: center;
    border: 0.5px solid $color-default;
    background-color: #ef98512c;
    color: $color-default;
    padding: 10px;
    width: 100%;
    border-radius: 10px;
}

@media screen and (max-width: 991px) {
    .payment-containner{
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 30px;
        .payment-body{
            flex-direction: column-reverse;
            .payment-body-wrapper-1{
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0px;
            }
            .payment-body-wrapper-2{
                width: 100%;
                margin-left: 0px;
                margin-bottom: 10px;
            }
        }
    }
}