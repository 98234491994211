.notify-container{
    width: 100%;
    padding: 20px;

    .notify-wrapper{
        max-width: 450px;
        margin-left: auto;
        margin-right: auto;

        .notify-list{
            padding: 10px;
            border-radius: 10px;
        }
    }
}

.noti-row{
    .body-containner{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        background-color: white;
        border: none;
        border-bottom: 1px solid lightgray;
    }
}

.dot {
    height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
  }