.tltd-searching-container{
    display: block;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    .tltd-searching-box{
        padding: 10px;
        background-color: white;

        .iconVisible{
            display: none;
        }
    }
    .tltd-searching-body{
        display: block;
        padding-top: 30px;
        padding-bottom: 30px;
        padding: 10px;
    }
    .tltd-searching-footer{
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .load-more{
            cursor: pointer;
            padding: 10px;
            color: black;
        }
    }

    .card-tag{
        display: block;
        position: relative;
        
        .tag{
            display: block;
            position: absolute;
            top: 10px;

            .tag-1{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }

            .tag-2{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .tag-right{
            display: block;
            position: absolute;
            right: 10px;
            bottom: 10px;

            .tag-1{
                display: block;
            }
        }
    }
    
    .card-body{
        .body-1{
            .card-title{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 1.5em;
            }
            .card-text{
                overflow: hidden;
                text-overflow: ellipsis;
                height: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.show_on_map{
    display: flex;
    position: fixed;
    bottom: 20px;
    left: 0px;
    width: 100%;
    justify-content: center;
}

@media screen and (max-width: 991px) {
    .tltd-searching-container{
        .tltd-searching-box{
            .iconVisible{
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 10px;
                justify-content: center;
                width: 100%;
                background-color: $color-default;
                color: white;
                box-shadow: $box-shadows;
                cursor: pointer;
                border: none;
                align-items: center;
            }

            .search-wrapper{
                display: none;
                transition: all .5s ease-in-out;
                margin-top: 10px;
                &.active{
                    display: block;
                }
            }
        }
    }
}