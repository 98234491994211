.consign-view-container{
    display: block;
    max-width: 1024px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    .consign-header{
        display: block;
        align-items: baseline;
    }

    .consign-picture{
        width: 100%;
        height: 100%;
    }

    .consign-info{
        display: flex;
        flex-direction: row;
        position: relative;

        .consign-info-container-1{
            display: flex;
            flex-direction: column;
            width: 60%;
            padding: 0px 20px 20px 0px;

            .header{
                font-size: x-large;
                font-weight: 400;
                padding-bottom: 20px;
                border-bottom: 0.5px solid $color-gray;
            }

            .consign-description{
                width: 100%;
                .col{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    .title{
                        font-size: large;
                        font-weight: 400;
                    }
                    .text{
                        padding: 10px 5px;
                        border-bottom: 0.5px solid $color-gray;
                        word-wrap: break-word;
                        word-break: break-all;
                    }
                }
                .consign-highlight{
                    width: 70%;
                    // max-width: 70%;
                    display: flex;
                    flex-direction: column;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    .text{
                        padding: 10px;
                        border: 0.5px solid $color-gray;
                        border-radius: 10px;
                        resize: none;
                    }
                }
            }
        }

        .consign-info-container-2{
            display: flex;
            flex-direction: column;
            width: 40%;
            padding: 0px 20px 20px 20px;
            .detail-wrapper{
                border: 0.5px solid $color-gray;
                border-radius: 10px;
            }
        }
    }
    .consign-map{
        width: 100%;
        .header{
            font-size: x-large;
            font-weight: 400;
            margin-bottom: 20px;
        }
        .mapWrapper{
            width: 100%;
            height: 60vh;
            border-radius: 10px;
        }
    }
}