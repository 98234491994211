.favorite-area
{
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    .error-toast{
        padding: 20px;
        background-color: rgb(252, 196, 196);
        color: rgb(227, 7, 7);
        border-radius: 10px;

        a.text-line{
            text-decoration: dotted ;
            font-weight: 500;
        }
    }

    .container{
        display: block;
        padding-top: 20px;
    }

    .description{
        background-color: rgb(255, 255, 255);
        padding: 20px;
        border-radius: 10px;
        border: 1px solid $color-gray;
    }

    .container-btn{
        display: flex;
        justify-content: end;
    }
}

.favorite-area-list
{
    display: block;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    .card-tag{
        display: block;
        position: relative;
        
        .tag{
            display: block;
            position: absolute;
            top: 10px;

            .tag-1{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }

            .tag-2{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
    }
    
    .card-body{
        .body-1{
            .card-title{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 1.5em;
            }
            .card-text{
                overflow: hidden;
                text-overflow: ellipsis;
                height: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    .favorite-area-list-footer{
        display: flex;
        justify-content: end;
    }
}