.consignment-container{

    .consignment-img-wrapper{
        .consignment-img{
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            border-radius: 10px;
            .img{
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
            }

            .consignment-detail{
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 65px;
                width: 100%;
                color: white;
                padding: 10px;
                border-radius: 10px;
                transition: 0.3s all ease-in-out;
                // background-color: rgba($color: #000000, $alpha: 0.9);

                &.show{
                    height: 100%;
                    background-color: rgba($color: #000000, $alpha: 0.9);
                }

                .header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 20px;
                }
                .discription{
                    display: none;
                    padding-left: 15px;
                    color: $color-default;

                    ul li{
                        list-style: disc;
                        color: $color-default;
                    }

                    &.show{
                        display: flex;
                        flex-wrap: wrap;
                    }
                }
            }
        }
    }
    
    .consignment-title{
        display: flex;
        font-size: 3.5em;
        font-weight: 400;
        width: 100%;
        justify-content: center;
        margin-bottom: 30px;
        color: $color-blue;
    
        >.underline{
            font-weight: 400;
            font-size: 1em;
            text-decoration: underline;
            text-decoration-color: $color-default;
        }
    }

    ul.wizard, ul.wizard li {
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        width: 100%;
    }
    ul.wizard {
        counter-reset: num;
    }
    ul.wizard li {
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    ul.wizard li::before {
        counter-increment: num;
        content: counter(num);
        width: 2.5em;
        height: 2.5em;
        display: flex;
        justify-content:center;
        align-items: center;
        line-height: 1.5em;
        border-radius: 50%;
        background: $color-default;
        color: white;
    }
    ul.wizard li ~ li::after {
        content: '';
        position: absolute;
        width: 100%;
        right: 50%;
        height: 4px;
        background-color: $color-default;
        top: calc(1.25em - 2px);
        z-index: -1;
    }
    ul.wizard li.active::before {
        background: $color-default;
        color: white;
    }
    ul.wizard .active ~ li::before,
    ul.wizard .active ~ li::after {
        background: #b7b7b7;
        color: rgb(255, 255, 255);
    }
    ul.wizard span {
        color: $color-gray;
        font-size: 12px;
    }
      
      /*  updated sample  */
      
    ul.wizard li.completed::before {      /*  number and circle  */
        background: $color-default;
        color: white;
    }
    ul.wizard li.completed span {         /*  text  */
        color: $color-gray;
    }
    ul.wizard li.completed + li::after {  /*  line after circle  */
        background: $color-default;
    }
    ul.wizard li.completed::after {       /*  line before circle  */
        background: $color-default;
    }

    .consignment-content{
        margin-top: 50px;

        >div{
            display: none;
            
            &.show{
                display: flex;
                flex-direction: column;
            }
        }

        .btn-content{
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .consignment-tab-wrapper{
            margin-bottom: 40px;
            color: $color-blue;
            .consignment-tab-section-1{
                padding-left: 100px;
                padding-right: 100px;
                margin-bottom: 20px;
    
                .title{
                    font-size: 20px;
                    font-weight: 400;
                    text-decoration: underline;
                    text-decoration-color: $color-default;
                    margin-bottom: 20px;
                    
                }
                .selected{
                    margin-bottom: 10px;
                }
            }

            //tab 2
            .consignment-tab2-section-1{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                // justify-content: center;
                margin-left: auto;
                margin-right: auto;
                max-width: 1024px;
                margin-bottom: 30px;
                .header-tab{
                    display: flex;
                    flex-direction: row;
                    align-items: baseline;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: underline;
                        text-decoration-color: $color-default;
                        padding-right: 20px;
                    }
                    .subtitle{
                        display: flex;
                        font-size: 14px;
                        font-weight: 300;
                    }
                }
            }

            .consignment-tab2-section-2{
                margin-left: auto;
                margin-right: auto;
                max-width: 1024px;
                height: 550px;
                display: flex;
                justify-content: center;
                margin-bottom: 30px;
                .ground-image{
                    width: 60%;
                    height: 100%;
                    background-color: #eaeaea;
                    border-radius: 10px;
                    // border: 3px ridge #000;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .ground-images{
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    width: 20%;
                    height: 100%;
                    .sub-ground{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 20px;
                        // width: 80%;
                        height: 30%;
                        background-color: #eaeaea;
                        border-radius: 10px;
                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                }
                .image-gallery{
                    width: 100%;

                    .image-gallery-thumbnail{
                        width: unset;
                        margin-top: 20px;
                        .thumnail-container{
                            display: block;
                            position: relative;
                            width: 100px;
                            height: 80px;

                            .delete{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                position: absolute;
                                top: -5px;
                                right: -5px;
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                border: none;
                                background-color: red;
                                color: white;
                                cursor: pointer;
                            }
                        }
                    }

                    
                }
                .image-gallery-thumbnail.active, 
                .image-gallery-thumbnail:focus,
                .image-gallery-thumbnail:hover
                {
                    border: 4px solid $color-default;
                }
                .image-gallery-content .image-gallery-slide .image-gallery-image{
                    height: 50vh !important;
                    .item-img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .consignment-tab2-section-3{
                margin-left: auto;
                margin-right: auto;
                max-width: 1024px;
                margin-bottom: 30px;
                .title{
                    font-size: 20px;
                    font-weight: 400;
                    text-decoration: underline;
                    text-decoration-color: $color-default;
                    padding-right: 20px;
                }
            }

            //tab 3
            .consignment-tab3-section-1{
                .image-container{
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 1024px;

                    .image-gallery{
                        width: 100%;
                    }
                    .image-gallery-thumbnail.active, 
                    .image-gallery-thumbnail:focus,
                    .image-gallery-thumbnail:hover
                    {
                        border: 4px solid $color-default;
                    }
                    .image-gallery-content .image-gallery-slide .image-gallery-image{
                        height: 60vh !important;
                        .image-preview{
                            width: 100%;
                            height: 100%;
                        }
                        
                    }
                }
            }

            .consignment-tab3-section-2{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-left: auto;
                margin-right: auto;
                max-width: 1024px;

                .column-1{
                    display: block;
                    width: 50%;
                    padding: 10px;

                    .header{
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .d-type{
                        font-size: 16px;
                        width:fit-content;
                        padding: 5px 20px;
                        border-radius: 5px;
                        border: 0.5px solid $color-default;
                        color: $color-default;
                        background-color: $color-white;
                        text-align: center;
                    }

                    .detail-wrapper{
                        padding-top: 20px;
                        padding-bottom: 20px;
                        display: flex;
                        flex-direction: row;
                        .profile-img{
                            width: 60px;
                            height: 60px;
                            border-radius: 50%;
                            margin-right: 20px;
                        }
                        .d-type{
                            font-size: 12px;
                            width:fit-content;
                            padding: 5px;
                            border-radius: 5px;
                            border: 0.5px solid $color-default;
                            color: $color-default;
                            background-color: $color-white;
                            text-align: center;
                        }

                        .quick{
                            font-size: 12px;
                            width:fit-content;
                            padding: 5px;
                            border-radius: 5px;
                            border: 0.5px solid $color-blue;
                            color: $color-blue;
                            background-color: $color-white;
                            text-align: center;
                        }
                    }
                    
                }
                .column-2{
                    display: block;
                    width: 50%;
                    padding: 10px;

                    .header{
                        font-size: 20px;
                        font-weight: 500;
                    }

                    .detail-wrapper{
                        border: 0.5px solid $color-gray;
                        border-radius: 10px;
                        margin-bottom: 20px;
                    }

                    .d-type{
                        font-size: 16px;
                        width:fit-content;
                        padding: 5px 20px;
                        border-radius: 5px;
                        border: 0.5px solid $color-default;
                        color: $color-default;
                        background-color: $color-white;
                        text-align: center;
                    }

                    .check-box-container{
                        padding: 10px 20px;
                        background-color: #CCE4FB;
                        color: #26A2F1;
                        border-radius: 5px;
                        cursor: pointer;
                        &.form-check{
                            padding-left: 2rem !important;
                        }
                        .form-check-input{
                            border-color: #CCE4FB !important;
                        }
                        .form-check-input:checked {
                            background-color: #26A2F1;
                            border-color: #26A2F1;
                        }
                    }
                }
            }
        }

        .tab-contact{
            display: block;
            position: relative;
            margin-bottom: 20px;
            width: 100%;
            height: 450px;
            background-image: url("../../../img/xd.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: left 20% center;

            .contact-wrapper{
                display: block;
                position: absolute;
                right: 10%;
                top: 35%;
                text-align: end;
                color: $color-default;
            }
        }
    }
}