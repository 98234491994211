.annonce-containner{
    display: block;
    color: $color-blue;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;

    .annonce-title{
        display: flex;
        font-size: 3.5em;
        font-weight: 400;
        width: 100%;
        justify-content: center;
        margin-bottom: 30px;
    
        >.underline{
            font-weight: 400;
            font-size: 1em;
            text-decoration: underline;
            text-decoration-color: $color-default;
        }
    }

    .annonce-detail{
        padding: 0px 10px;
        .underline{
            text-decoration: underline;
            text-decoration-color: $color-default;
        }
    }
}

.announceresult-banner{
    width: 100%;
    height: 300px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: rgb(237, 237, 237);
    
    .banner-img{
        position: relative;
        height: 80%;
        width: auto;
        z-index: 10;
    }
}

.announceresult-container{
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding: 0px 10px 10px 10px;
    z-index: 11;
    
    .announce-section{
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: $color-white;
        width: 100%;
        .title-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .title{
                font-size: 2.5rem;
                font-weight: 400;
                margin-bottom: 30px;
                // color: rgb(0, 0, 0);
            }
        }
        

        .announce-section-row{
            display: flex;
            flex-direction: row;
            width: 100%;
            .announce-section-col-1{
                padding-right: 10px;
                width: 20%;

                .containner{
                    display: block;
                    background-color: white;
                    padding: 10px;
                    border-radius: 10px;
                }
            }
            .announce-section-col-2{
                position: relative;
                padding: 10px;
                width: 80%;
                .header{
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .user-img{
                        width: 64px;
                        height: 64px;
                        object-fit: cover;
                        border-radius: 50%;
                    }

                    .oversize{
                        font-size: 1.8rem;
                        font-weight: 500;
                        color: $color-default;
                    }

                    .text-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-default;
                        color: white;
                        width: 90%;
                        border-radius: 50px;
                        // width: fit-content;

                        .icon{
                            margin-left: -20px;
                        }
                        .label{
                            text-align: center;
                            line-height: 1.5em;

                            .label-1{
                                font-size: 1.3em;
                                font-weight: 400;
                            }
                        }
                    }

                    .date{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                }
            
                .text-description{
                    .text-detail{
                        line-height: 1.5;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 4.5em;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
    }
}

.text-error-box{
    display: block;
    border: 2px solid red;
    padding: 20px;
    word-break: break-all;
    border-radius: 10px;
    background-color: rgba(255, 0, 0, 0.2);

    .error-list{
        padding-left: 20px;
        li{
            color: red;
            list-style: disc;
        }
    }
}

.announce_view{
    width: 100%;
    min-height: 88vh;
    background-color: rgb(233, 233, 233);
    padding-bottom: 100px;
    padding-top: 30px;

    .container{
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
        
        .container-1{
            position: relative;
            width: 100%;
            background-color: white;
            padding: 10px;
            border-radius: 20px;
            padding-bottom: 30px;
            margin-bottom: 50px;
            // margin-top: 20px;

            .gotoBack{
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 2;
                top: 30px;
                left: 30px;
                width: 40px;
                height: 40px;
                background-color: #f4f4f4;
                border-radius: 50%;
                cursor: pointer;
            }

            .date{
                position: absolute;
                z-index: 1;
                top: 30px;
                right: 30px;
            }

            .flex-1{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 30px;

                .img-profile{
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    margin-bottom: 10px;
                }

                .oversize{
                    font-size: 1.8rem;
                    font-weight: 500;
                    color: #EF9751;
                }

                .text-wrapper{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-default;
                    color: white;
                    width: 70%;
                    border-radius: 50px;
                    padding: 5px 0;
        
                    .icon{
                        margin-left: -20px;
                    }
                    .label{
                        text-align: center;
                        line-height: 1.5em;
        
                        .label-1{
                            font-size: 1.3em;
                            font-weight: 400;
                        }
                    }
                }
            }
            .flex-2{
                max-width: 700px;
                margin-left: auto;
                margin-right: auto;

                .label{
                    font-weight: 500;
                    color: $color-default;
                }
            }
        }

        .container-2{
            width: 100%;
            background-color: white;
            border-radius: 20px;
            padding: 20px;
            margin-bottom: 40px;
            position: relative;

            .container-flex{
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: row;
                align-items:start;
                

                .img-profile{
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-right: 20px;
                }

                .form-textarea-1{
                    width: 100%;
                    border-radius : 25px;
                    border : 0.5px solid gray;
                    padding: 10px 20px;
                    resize: none;
                    &:focus{
                        outline: none;
                    }
                }
            }
            .backdrop-ovelay{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #000000ae;
                border-radius: 20px;

                .container{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .container-post{
            width: 100%;
            background-color: white;
            border-radius: 20px;
            padding: 20px;
            position: relative;

            

            .container-flex{
                max-width: 800px;
                margin-left: auto;
                margin-right: auto;
                display: flex;
                flex-direction: row;
                align-items:start;

                .img-profile{
                    width: 70px;
                    height: 70px;
                    border-radius: 50%;
                    margin-right: 50px;
                }

                .text{
                    color: gray;
                }
                
                .form-textarea-1{
                    width: 100%;
                    border-radius : 25px;
                    border : 0.5px solid gray;
                    padding: 10px 20px;
                    resize: none;
                    &:focus{
                        outline: none;
                    }
                }
            }
        }
    }
}

.popover ul li.li{
    padding: 5px 10px;  
    cursor: pointer;

    &:hover{
        background-color: #fbe7d7;
    }
}

@media screen and (max-width: 991px) {
    .announceresult-container{
        .announce-section{
            .title-container{
                display: flex;
                flex-direction: column;
                justify-content: none;
                align-items: center;
                margin-bottom: 30px;
                .title{
                    font-size: 2rem;
                    font-weight: 400;
                    margin-bottom: 20px;
                    // color: rgb(0, 0, 0);
                }
            }
            .announce-section-row{
                flex-direction: column;
                .announce-section-col-1{
                    width: 100%;
                }
                .announce-section-col-2{
                    width: 100%;
                }
            }
        }
    }

    .announceresult-banner{
        .banner-img{
            height: 40%;
        }
    }
}