.help-contailner{
    display: block;
    position: relative;
    width: 100%;
    height: 85vh;
    margin-bottom: 50px;
    // background: rgb(121,121,121);
    // background: linear-gradient(90deg, rgba(121,121,121,0.8841911764705882) 0%, rgba(255,255,255,0) 100%);
    background-image: url('../../../img/Help\ Center/help.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left bottom;
    

    .help-text{
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        top: 20%;
        left: 20%;

        .title{
            font-size: 5rem;
            font-weight: 500;
            color: $color-blue;
        }
        .sub-title{
            font-size: 2rem;
            font-weight: 500;
            color: $color-white;
            text-shadow: $text-shadows;
        }
    }
}

.help-detail-container,
.help-detail-container-2{
    // display: flex;
    // flex-direction: column;
    // align-items: center;
    padding: 50px;
    background-color: $color-white;

    &:last-child{
        margin-bottom: 20px;
    }

    .title{
        color: $color-default;
        text-align: center;
        font-weight: 400;
        font-size: 18px;
    }

    // .tab-containner{
        // display: flex;
        // flex-direction: row;
        // justify-content: center;

        .tab{
            display: flex;
            flex-direction: column;
            align-items: center;
            // width: 25%;
            margin-top: 20px;
            margin-bottom: 20px;
            // background-color: $color-white;
            color: $color-gray;
            border-radius: 10px;
            cursor: pointer;

            .tabs{
                width: 100%;
                padding: 30px;
                border-radius: 10px;
                text-align: center;
                background-color: $color-white;
            }

            &.active{
                color: $color-default;
            }
        }
    // }

    .help-tab-wrapper{
        width: 100%;
        padding: 30px;
        background-color: $color-white;

        .tab-close{
            color: $color-default;
            text-align: end;
            
            .icon{
                font-size: x-large;
                cursor: pointer;
            }
        }

        .tab-1{
            padding-top: 30px;
            .img_detail{
                width: 100%;
            }
        }
        .tab-2{
            padding-top: 30px;
            .img_detail{
                width: 100%;
            }
        }
    }
}

.question-hit{
    background-color: $color-secondary;
    padding: 20px 20%;
    margin-bottom: 40px;
    .title{
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 30px;
    }
}

.company-info{
    padding: 0px 20px;

    .map-info{
        width: 100%;
        height: 80vh;
        border-radius: 20px;
    }
}

@media screen and (max-width: 991px) {
    .help-contailner{
        height: 65vh;
        background-position: right -50px top ;
        .help-text{
            top: 100px;
            left: 20px;
            margin-left: 10px;
            
            .title{
                font-size: 3rem;
            }
        }
    }

    .help-detail-container{
        padding: 10px;
        
    }

    .question-hit{
        padding: 20px 10px;

        .vr{
            border-right: none;
        }
    }

    // .company-info{
    //     .map-info{
    //         padding: 0px;
    //     }
    // }
}