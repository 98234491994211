.banner-province-img{
    width: 100%;
    height: 85vh;
    background-image: url('../../../img/green-landscapes.png');
    background-repeat: no-repeat;
    background-position: left -10px center;
    background-size: cover;

    .province-search-container{
        width: 100%;
        position: absolute;
        top: 50%;
        bottom: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;

        .search-group{
            width: 50% ;
            margin-left: auto;
            margin-right: auto;
        }
        
        .label{
            display: flex;
            justify-content: center;
            font-size: 5em;
            font-weight: 500;
            color: $color-white;
        }
    }
}

.province-section-1{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: $color-secondary;

    .tag{
        display: block;
        position: absolute;
        top: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .tag-2{
        display: block;
        position: absolute;
        top: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .title{
        font-size: 2.5rem;
        font-weight: 400;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 991px) {
    .banner-province-img{
        .province-search-container{
            width: 95%;
            top: 50%;
            bottom: 50%;
            margin-left: 10px;
            margin-right: 10px;
            .search-group{
                width: 100% ;
                margin-left: 0px;
                margin-right: 0px;
            }
            .label{
                font-size: 2.5em;
            }
        }
    }

    .province-section-1{
        padding-left: 10px;
        padding-right: 10px;
    }
}