// .gallery-icon-size{
//     font-size: 50px;
// }


// .image-gallery-left-nav,
// .image-gallery-right-nav{
//     padding: 0px 30px !important;
// }
// .image-gallery-right-nav{
//     border-top-right-radius: 10px;
//     border-bottom-right-radius: 10px;
//     background: rgb(0,0,0);
//     background: linear-gradient(270deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%);
// }
// .image-gallery-left-nav{
//     border-top-left-radius: 10px;
//     border-bottom-left-radius: 10px;
//     background: rgb(0,0,0);
//     background: linear-gradient(90deg, rgba(0,0,0,0.2) 0%, rgba(255,255,255,0) 100%);
// }

// .image-gallery-icon{
//     height: 100%;
//     opacity: 0.8;
// }

.gotomap{
    position: fixed;
    // width: 100%;
    z-index: 1030;
    left: 50%;
    bottom: 20px;
    transform: translate(-50%);
    >.btn{
        display: flex;
        padding: 10px;
        color: #fff;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
        background-color: rgb(46, 134, 193);
        transition: 0.3s all ease;
        &:hover{
            background-color: rgba(46, 134, 193,0.8);
            transform: translateY(-5px);
        }
    }
}

.home-container{
    .container-slider{
        position: relative;
        .image{
            width: 100%;
            object-fit: contain;
            // border: 1px solid black ;
            // height: 900px;
        }
        .home-scrolldown{
            position: absolute;
            bottom: 60px;
            left: 50%;
            animation: MoveUpDown 1.5s linear infinite;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .scrolldown{
                width: 32px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 50%;
                background-color: black;
                color: #fff;
                
            }
        }
        
    }
    .search-container{
        position: relative;
        display: block;
        // margin-left: 100px;
        // margin-right: 100px;
        margin-bottom: 50px;
        max-width: $max-width;
        // height: 120px;
        // background-color: gray;
        margin-left: auto;
        margin-right: auto;
        margin-top: -100px;
        z-index: 10;
    
        .iconVisible{
            display: none;
        }
        .btn-wrapper{
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
    
        .search-wrapper{
            display: block;
            position: relative;
            // width: 100%;
            // height: 80px;
            border-radius: 10px;
            padding: 10px;
            background-color: white;
            box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
    }
    .home-section-1{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        max-width: $max-width;
        display: flex;
        flex-direction: row;
        justify-content: center;

        .flex-1{
            width: 50%;
            padding: 10px;
        }
        .flex-2{
            width: 50%;
            padding: 10px;
            .img-1{
                object-fit: cover;
                width: 100%;
                border-radius: 10px;
            }
        }
    }
    
    .home-section-2{
        display: flex;
        flex-direction: column;
        // margin-bottom: 20px;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        padding-top: 50px;
        padding-bottom: 50px;
    
        .card-tag{
            display: block;
            position: relative;
            
            .tag{
                display: block;
                position: absolute;
                top: 10px;
    
                .tag-1{
                    display: block;
                    width: fit-content;
                    padding-left: 10px;
                    padding-right: 10px;
                }
    
                .tag-2{
                    display: block;
                    width: fit-content;
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .tag-3{
                    display: block;
                    width: fit-content;
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
            .tag-right{
                display: block;
                position: absolute;
                right: 10px;
                bottom: 10px;

                .tag-1{
                    display: block;
                }
            }
        }
        
    
        .title{
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 30px;
        }
        .card-body{
            .body-1{
                .card-title{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    height: 1.5em;
                }
                .card-text{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 4.5em;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    -webkit-box-orient: vertical;
                }
            }
        }
        .section-button-last{
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .home-section-3{
        display: block;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    
        .title{
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 30px;
        }

        td[scope="row"], 
        thead tr {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 999;
        }
    }
    
    .home-section-4{
        display: flex;
        flex-direction: column;
        padding-top: 50px;
        padding-bottom: 50px;
        // background-color: #f1f1f1;
        background-image: url('../../../img/Home/bg-gray3.jpeg');
        background-repeat: no-repeat;
        background-position: left top;
        background-size: cover;
        // background-attachment: fixed;
    
        .home-section-4-box{
            display: block;
            max-width: $max-width;
            margin-left: auto;
            margin-right: auto;
        }
        .title{
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 30px;
        }
    
        .header{
            display: flex;
            flex-direction: column;
            align-items: center;
    
            .user-img{
                width: 64px;
                height: 64px;
                object-fit: cover;
                border-radius: 50%;
            }
    
            .oversize{
                font-size: 1.8rem;
                font-weight: 500;
                color: $color-default;
            }
    
            .text-wrapper{
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $color-default;
                color: white;
                width: 90%;
                border-radius: 50px;
                // width: fit-content;
    
                .icon{
                    margin-left: -20px;
                }
                .label{
                    text-align: center;
                    line-height: 1.5em;
    
                    .label-1{
                        font-size: 1.3em;
                        font-weight: 400;
                    }
                }
            }
    
            .date{
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }
    
        .text-description{
            .text-detail{
                font-size: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    
        .section-button-last{
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    
    .home-section-5{
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        padding-top: 50px;
        padding-bottom: 50px;
        .title{
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 30px;
            text-align: center;
        }
        .header{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .icon{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                margin-bottom: 20px;
            }
        }
        
        .card{
            height: 100%;
            li{
                margin-bottom: 5px;
                &::before{
                    background-color: $color-default;
                    border-radius: 50%;
                    content: "";
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    height: 8px;
                    width: 8px;
                }
            }
        }
    
        
    }
    
    .home-section-6{
        display: flex;
        flex-direction: column;
        margin-bottom: 100px;
        padding-top: 50px;
        padding-bottom: 50px;
        background-color: #f1f1f1;
        .title{
            font-size: 2.5rem;
            font-weight: 400;
            margin-bottom: 30px;
        }
    
        .section-button-last{
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .home-section-6-box{
            display: block;
            max-width: $max-width;
            margin-left: auto;
            margin-right: auto;

            .home-section-6-body{
                .image-container{
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-rows: repeat(2, 12vw);
                    gap: 10px;
                    margin-bottom: 20px;

                    .gallery__item{
                        position: relative;
                        cursor: pointer;
                        z-index: 0;
                        .backdrop{
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            z-index: 1;
                            padding: 20px 10px;
                            background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,1) 100%);
    
                            .text{
                                color: #fff;
                                font-weight: 500;
                            }
                        }
                        .gallery__img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                        &.gallery_item-1{
                            grid-column-start: 1;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 3;
                        }
                        &.gallery_item-2{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.gallery_item-3{
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.gallery_item-4{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                        &.gallery_item-5{
                            grid-column-start: 4;
                            grid-column-end: 5;
                            grid-row-start: 2;
                            grid-row-end: 3;
                        }
                    }
                }
                .video-container{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(1, 1fr);
                    gap: 10px;
                    margin-bottom: 20px;
                    .video_item{
                        .text{
                            color: #000;
                        }
                        &.video_item-1{
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.video_item-2{
                            grid-column-start: 2;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.video_item-3{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                    }
                }
                .tiktok-container{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    grid-template-rows: repeat(1, 1fr);
                    gap: 10px;
                    margin-bottom: 20px;
                    background-color: white;
                    border-radius: 10px;
                    .tiktok_item{
                        &.tiktok_item-1{
                            grid-column-start: 1;
                            grid-column-end: 2;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.tiktok_item-2{
                            grid-column-start: 2;
                            grid-column-end: 3;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                        &.tiktok_item-3{
                            grid-column-start: 3;
                            grid-column-end: 4;
                            grid-row-start: 1;
                            grid-row-end: 2;
                        }
                    }
                }
            }
        }
    
        // .card{
        //     .card-body{
        //         .card-title{
        //             white-space: nowrap;
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //         }
        //         .card-text{
        //             white-space: nowrap;
        //             overflow: hidden;
        //             text-overflow: ellipsis;
        //         }
        //     }
        //     .card-footer{
        //         display: flex;
        //         justify-content: space-between;
        //         padding-left: 10px;
        //         padding-right: 10px;
        //         padding-bottom: 20px;
    
        //         .tags{
        //             display: flex;
        //             align-items: center;
        //             padding: 2px 10px;
        //             margin-left: 5px;
        //             margin-right: 5px;
        //             border-radius: 20px;
        //             background-color: $color-sky;
        //         }
        //     }
        // }
    }
    
    .home-section-7{
        width: 100%;
        height: inherit;
    
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

@keyframes MoveUpDown {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
}

@media screen and (max-width: 991px) {
    .gallery-icon-size{
        font-size: 25px;
    }
    .image-gallery-left-nav,
    .image-gallery-right-nav{
        padding: 0px 10px !important;
    }

    .home-container{
        .home-scrolldown{
            display: none !important;
        }
        
        .search-container{
            margin-top: 20px;
            padding: 10px;
            .btn-wrapper{
                display:none;
                margin-bottom: 10px;
                button{
                    margin-bottom: 10px;
                }
            }
            .iconVisible{
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 10px;
                justify-content: center;
                width: 100%;
                background-color: $color-default;
                color: white;
                box-shadow: $box-shadows;
                cursor: pointer;
                border: none;
                align-items: center;
            }
            .search-wrapper{
                display: none;
                transition: all .5s ease-in-out;
                margin-top: 10px;
                &.active{
                    display: block;
                }
            }
        }
    
        .home-section-1{
            padding-left: 10px;
            padding-right: 10px;
            flex-direction: column;

            .flex-1{
                width: 100%;
            }
            .flex-2{
                width: 100%;
            }
        }
    
        .home-section-2{
            padding-left: 10px;
            padding-right: 10px;
            
        }
        .home-section-3{
            width: 100%;   
        }
        .home-section-4{
            padding-left: 10px;
            padding-right: 10px;
    
            .home-section-4-box{
                max-width: none !important;
                margin-left: 0px !important;
                margin-right: 0px !important;
            }
        }
        .home-section-5{
            padding-left: 10px;
            padding-right: 10px;
        }
    
        .home-section-6{
            padding-left: 10px;
            padding-right: 10px;
    
            .home-section-6-box{
                // max-width: none !important;
                // margin-left: 0px !important;
                // margin-right: 0px !important;

                .image-container{
                    grid-template-columns: repeat(1, 1fr) !important;
                    grid-template-rows: repeat(5, 1fr) !important;

                    .gallery__item{
                        &.gallery_item-1{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 1 !important;
                            grid-row-end: 2 !important;
                        }
                        &.gallery_item-2{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 2 !important;
                            grid-row-end: 3 !important;
                        }
                        &.gallery_item-3{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 3 !important;
                            grid-row-end: 4 !important;
                        }
                        &.gallery_item-4{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 4 !important;
                            grid-row-end: 5 !important;
                        }
                        &.gallery_item-5{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 5 !important;
                            grid-row-end: 6 !important;
                        }
                    }
                }
                .video-container{
                    grid-template-columns: repeat(1, 1fr) !important;
                    grid-template-rows: repeat(3, 1fr) !important;

                    .video_item{
                        &.video_item-1{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 1 !important;
                            grid-row-end: 2 !important;
                        }
                        &.video_item-2{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 2 !important;
                            grid-row-end: 3 !important;
                        }
                        &.video_item-3{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 3 !important;
                            grid-row-end: 4 !important;
                        }
                    }
                }
                .tiktok-container{
                    grid-template-columns: repeat(1, 1fr) !important;
                    grid-template-rows: repeat(3, 1fr) !important;

                    .tiktok_item{
                        &.tiktok_item-1{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 1 !important;
                            grid-row-end: 2 !important;
                        }
                        &.tiktok_item-2{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 2 !important;
                            grid-row-end: 3 !important;
                        }
                        &.tiktok_item-3{
                            grid-column-start: 1 !important;
                            grid-column-end: 2 !important;
                            grid-row-start: 3 !important;
                            grid-row-end: 4 !important;
                        }
                    }
                }
            }
        }
    
        .home-section-7{
            .title{
                flex-direction: column;
            }
        }
    }
}