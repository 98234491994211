
.about-containter{
    display: block;
    padding-left: 10px;
    padding-right: 10px;

    .about-banner{
        width: 100%;
        height: 80vh;
        border-radius: 10px;
        background-image: url('../../../img/Mask\ Group\ 32.png');
        background-repeat: no-repeat;
        background-position: left center;
        background-size: cover;

        &::before{
            content: "";
            position: absolute;
            width: 98.6%;
            height: 80vh;
            border-radius: 10px;
            background: rgba(0,0,0,.5);
        }

        .text-header{
            position: absolute;
            top: 40%;
            left: 40%;
            font-size: 5em;
            font-weight: 500;
            color: white;
        }
    }

    
}

.about-content-1{
    padding: 50px 80px;
    background-color: $color-secondary;
}

.about-content-2{
    padding: 50px 80px;
}

.about-content-3{
    padding: 50px 80px;
    background-color: $color-secondary;
}

.about-content-4{
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    max-width: $max-width;
    margin-left: auto;
    margin-right: auto;
    padding-top: 50px;
    padding-bottom: 50px;
    .title{
        font-size: 2.5rem;
        font-weight: 400;
        margin-bottom: 30px;
        text-align: center;
    }
    .header{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon{
            width: 100px;
            height: 100px;
            border-radius: 50%;
            margin-bottom: 20px;
        }
    }
    
    .card{
        height: 100%;
        li{
            margin-bottom: 5px;
            &::before{
                background-color: $color-default;
                border-radius: 50%;
                content: "";
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 2px;
                height: 8px;
                width: 8px;
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .about-containter{
        .about-banner{
            &::before{
                width: 95%;
            }
            .text-header{
                top: 45%;
                left: 25%;
                font-size: 3em;
            }
        }
    }

    .about-content-1{
        padding: 20px 10px;
    }

    .about-content-2{
        padding: 20px 10px;
    }

    .about-content-3{
        padding: 20px 10px;
    }
}