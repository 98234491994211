.map-container{
    .map-wrapper{
        display: flex;
        flex-direction: row;
        padding: 0px 20px;
        
        .map-wrapper-1{
            padding-left: 10px;
            padding-right: 10px;
            width: calc(40% - 0%);
            transition: 1s all ease;
            .loading{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            }
            .wrapper-body{
                // display: inline-block;
                overflow-y: auto;
                height: 85vh;
    
                .fixed{
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    position: relative;
                    background-color: $color-white;
                    height: 40px;
                    width: 100%;
                    // bottom: 0;
                }
    
                .card{
                    display: block;
                    .card-body{
                        .body-1{
                            .card-title{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                height: 1.5em;
                            }
                            .card-text{
                                overflow: hidden;
                                text-overflow: ellipsis;
                                height: 4.5em;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
            .card-tag{
                display: block;
                position: relative;
                
                .tag{
                    display: block;
                    position: absolute;
                    top: 10px;

                    .tag-1{
                        display: block;
                        width: fit-content;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                    .tag-2{
                        display: block;
                        width: fit-content;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                    .tag-3{
                        display: block;
                        width: fit-content;
                        padding-left: 10px;
                        padding-right: 10px;
                    }
                }

                .tag-right{
                    display: block;
                    position: absolute;
                    right: 10px;
                    bottom: 10px;
    
                    .tag-1{
                        display: block;
                    }
                }

                .btn-favorite{
                    position: absolute;
                    right: 10px;
                    top: 10px;
                    z-index: 5;
                    .icon{
                        font-size: 18px;
                    }
                }
                .btn-share{
                    position: absolute;
                    right: 30px;
                    top: 10px;
                    z-index: 5;
                    .icon{
                        font-size: 18px;
                    }
                }
            }
        }
    
        .map-wrapper-2{
            // padding-left: 10px;
            // padding-right: 10px;
            width: calc(60% - 0%);
            border-radius: 10px;
            transition: 1s all ease;
            position: relative;

            .search-box{
                position: absolute;
                left: 50%;
                top: 50px;
                transform: translate(-90%, -30%);
                -ms-transform: translate(-90%, -30%);
                z-index: 20;
            }
            .btn-fullmap{
                display: flex;
                flex-direction: row;
                position: absolute;
                z-index: 10;
                top: 100px;
                left: 0px;
                padding: 30px 10px;
                border: none;
                background-color: $color-white;
                cursor: pointer;
                box-shadow: $box-shadows;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
                transition: 1s all ease;
            }
    
            .mapWrapper{
                position: relative;
                width: 100%;
                height: 85vh;
                border-radius: 10px;
                .loading{
                    position: absolute;
                    z-index: 100;
                    top: 45%;
                    left: 45%;
                    background-color: $color-white;
                    border-radius: 10px;
                }
                .btn-fullmap{
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    z-index: 10;
                    top: 100px;
                    left: 0px;
                    padding: 30px 10px;
                    border: none;
                    background-color: $color-white;
                    cursor: pointer;
                    box-shadow: $box-shadows;
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    transition: 1s all ease;
                }
                .form-datalist{
                    position: absolute;
                    z-index: 9;
                    top: 10px;
                    left: 35%;
                }
                .btn-filter{
                    position: absolute;
                    z-index: 9;
                    top: 10px;
                    right: 10px;
                    padding: 5px;
                    color: #000;
                    display: flex;
                    align-items: center;
                    border-radius: 5px;

                    .icon{
                        font-size: 20px;
                    }
                }
                .gm-style-iw{
                    width: 330px !important;
                    .image-gallery{
                        height: 200px;
                        border-radius: 10px;
                        .image-gallery-left-nav,
                        .image-gallery-right-nav{
                            padding: 0px 8px !important;
                            .gallery-icon-size{
                                font-size: 20px;
                            }
                        }
                        .image-gallery-image{
                            object-fit: cover;
                            width: 300px;
                            height: 200px;
                            border-radius: 10px;
                            overflow: hidden;
                        }
                    }
                }

                .gm-style-iw-d{
                    overflow-x: hidden !important;
                }
            }
        }
    
        &.fullmap{
            transition: 1s all ease;
            .map-wrapper-1{
                width: (0%);
                padding-left: 0px;
                padding-right: 0px;
                transition: 1s all ease;
                .wrapper-body{
                    display: none;
                }
            }
    
            .map-wrapper-2{
                padding-left: 0px;
                padding-right: 0px;
                width: calc(100% - 0px);
                transition: 1s all ease;
            }
        }
    }
}

.modal{
    .modal-body{
        .header{
            font-size: large;
            font-weight: 400;
        }
        .property-type-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 10px 0px;

            
        
            .nav-tabs{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                text-align: center;
                background-color: white;
                width: 100%;
                height: 100px;
                margin-left: 10px;
                margin-right: 10px;
                border-radius: 10px;
                color: $color-gray;
                box-shadow: $box-shadows;
                font-size: large;
                .icon{
                    font-size: large;
                }
    
                &.active{
                    color: $color-default;
                }
            }
        }
    }
}

.info-windown{
    width: 300px;
    max-height: 250px;
    position: relative;
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    word-break: break-all;
    overflow-y: auto;
    overflow-x: hidden;

    .image-fill{
        width: 100%;
        height: 200px;
        
    }
}

@media screen and (max-width: 991px) {
    .map-container{
        .top-wrapper{
            flex-direction: row;
            padding: 10px;
        }
        .map-wrapper{
            flex-direction: column;
            padding: 0px;
            .map-wrapper-1{
                // padding-top: 30px;
                width: 100%;
                position: fixed;
                bottom: 0px;
                left: 0px;
                right: 0px;
                z-index: 10;
                height: 50%;
                background-color: $color-white;
                // padding-right: 10px;
                overflow-y: auto;
                overflow-x: hidden;
                padding-bottom: 30px;
                transition: 1s all ease;
                .wrapper-body{
                    height: auto;
    
                    .card{
                        display: flex;
                        flex-direction: row;
                        .card-tag{
                            width: 40%;
                            // height: 200px;
                            .card-img{
                                border-top-right-radius: 0px;
                                border-bottom-left-radius: 10px;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        .card-body{
                            width: 60%;
                            .body-2{
                                flex-direction: column;
                            }
                        }
                    }
                }
                
            }
        
            .map-wrapper-2{
                width: 100%;
                padding-left: 0px;
                padding-right: 0px;
                transition: 1s all ease;
                .mapWrapper{
                    width: 100%;
                    height: 85vh;
                    border-radius: 0px;
                    .gm-style-iw-d{
                        overflow-x: hidden !important;
                        overflow-y: auto !important;
                    }
    
                    .btn-fullmap{
                        display: flex;
                        justify-content: center;
                        position: fixed;
                        top: auto;
                        left: 0px;
                        right: 0px;
                        bottom: 50%;
                        border-radius: 0px;
                        padding: 10px 0px;
                        box-shadow: none;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                        transition: 1s all ease;
                    }
                }
            }
    
            &.fullmap{
                transition: 1s all ease;
                .map-wrapper-1{
                    height: 0%;
                    width: 100%;
                    background-color: $color-white;
                    transition: 1s all ease;
                }
                .map-wrapper-2{
                    transition: 1s all ease;
                    .mapWrapper{
                        .btn-fullmap{
                            bottom: 0%;
                        }
                    }
                }
            }
        }
    }
}