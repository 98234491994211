.forsale-contianer{
    display: block;
    color: $color-blue;

    .forsale-title{
        display: flex;
        font-size: 3.5em;
        font-weight: 400;
        width: 100%;
        justify-content: center;
        margin-bottom: 30px;
    
        >.underline{
            font-weight: 400;
            font-size: 1em;
            text-decoration: underline;
            text-decoration-color: $color-default;
        }
    }

    ul.wizard, ul.wizard li {
        margin: 0;
        padding-left: 30px;
        padding-right: 30px;
        display: flex;
        width: 100%;
    }
    ul.wizard {
        counter-reset: num;
    }
    ul.wizard li {
        flex-direction: column;
        align-items: center;
        position: relative;
    }
    ul.wizard li::before {
        counter-increment: num;
        content: counter(num);
        width: 2.5em;
        height: 2.5em;
        display: flex;
        justify-content:center;
        align-items: center;
        line-height: 1.5em;
        border-radius: 50%;
        background: $color-default;
        color: white;
    }
    ul.wizard li ~ li::after {
        content: '';
        position: absolute;
        width: 100%;
        right: 50%;
        height: 4px;
        background-color: $color-default;
        top: calc(1.25em - 2px);
        z-index: -1;
    }
    ul.wizard li.active::before {
        background: $color-default;
        color: white;
    }
    ul.wizard .active ~ li::before,
    ul.wizard .active ~ li::after {
        background: #b7b7b7;
        color: rgb(255, 255, 255);
    }
    ul.wizard span {
        color: $color-gray;
        font-size: 12px;
    }
      
      /*  updated sample  */
      
    ul.wizard li.completed::before {      /*  number and circle  */
        background: $color-default;
        color: white;
    }
    ul.wizard li.completed span {         /*  text  */
        color: $color-gray;
    }
    ul.wizard li.completed + li::after {  /*  line after circle  */
        background: $color-default;
    }
    ul.wizard li.completed::after {       /*  line before circle  */
        background: $color-default;
    }

    .tab-content{
        margin-top: 50px;
        >div{
            display: none;
            
            &.show{
                display: flex;
                flex-direction: column;
                
            }
            .tab-wrapper{
                margin-bottom: 40px;
                // tap1
                .tab-section-1{
                    padding-left: 100px;
                    padding-right: 100px;
                    margin-bottom: 20px;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: underline;
                        text-decoration-color: $color-default;
                        margin-bottom: 30px;
                        
                    }
                    .selected{
                        margin-bottom: 30px;
                    }

                    .check-box-container{
                        padding: 10px 20px;
                        background-color: #e6fae3;
                        color: #329e0e;
                        border-radius: 5px;
                        &.form-check{
                            padding-left: 2rem !important;
                        }
                        .form-check-input{
                            border-color: #CCE4FB !important;
                        }
                        .form-check-input:checked {
                            background-color: #329e0e;
                            border-color: #329e0e;
                        }
                    }
                }
                .tab-section-2{
                    padding-left: 100px;
                    padding-right: 100px;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: underline;
                        text-decoration-color: $color-default;
                        margin-bottom: 30px;
                        
                    }
                    .check-box-container{
                        padding: 10px 20px;
                        background-color: #CCE4FB;
                        color: #26A2F1;
                        border-radius: 5px;
                        &.form-check{
                            padding-left: 2rem !important;
                        }
                        .form-check-input{
                            border-color: #CCE4FB !important;
                        }
                        .form-check-input:checked {
                            background-color: #26A2F1;
                            border-color: #26A2F1;
                        }
                    }
                }
                .tab-section-3{
                    padding-left: 100px;
                    padding-right: 100px;
                    margin-bottom: 30px;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: underline;
                        text-decoration-color: $color-default;
                        margin-bottom: 10px;
                        
                    }
                }
                .tab-section-4{
                    background-color: $color-secondary;
                    text-align: center;
                    margin-bottom: 30px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    .text{
                        color: #5F5F5F;
                        font-size: 3.5em;
                        .text-inner{
                            color: transparent;
                            background-color: $color-default;
                            text-shadow: -4px 4px 6px rgba(255, 255, 255, 0.304);
                            font-size: 1em;
                            font-weight: 500;
                            -webkit-background-clip: text;
                            -moz-background-clip: text;
                            background-clip: text;
                        }
                    }
                }
                .tab-section-5{
                    .contain-center{
                        display: flex;
                        justify-content: center;
                    }
                    .box{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        border-radius: 10px;
                        padding: 5px;
                        width: 100%;
                        height: 100%;
                        
                        .box-body{
                            display: flex;
                            flex-direction: column;
                            padding-left: 10px;
                            padding-right: 10px;
                            color: white;

                            .box-img{
                                width: 100%;
                                margin-top: 10px;
                                margin-bottom: 30px;
                            }

                            .title{
                                font-size: 20px;
                                font-weight: 500;
                            }
                            .sub-title{
                                font-size: 12px;
                                margin-bottom: 20px;
                            }
                            .box-content{
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                margin-bottom: 30px;

                                .box-price{
                                    margin-top: 80px;
                                    .text-1{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                        width: 40%;
                                    }
                                    .text-2{
                                        display: flex;
                                        flex-direction: column;
                                        margin-left: 10px;
                                        width: 60%;
                                        margin-top: -25px;
                                        .text-sm{
                                            font-weight: 200;
                                            font-size: 8px;
    
                                            .price-dis{
                                                font-size: 14px;
                                                text-decoration: line-through;
                                                text-decoration-color: red;
                                            }
                                        }
                                        .text-bold{
                                            font-size: 2em;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: baseline;
                                        }
                                    }
                                    .content-text{
                                        display: flex;
                                        flex-direction: column;
                                        border-left: 3px solid rgb(255, 255, 255);
                                    }
                                }
                            }
                        }
                        .box-footer{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                        }
                    }
                }
                // tab2
                .tab2-section-1{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    // justify-content: center;
                    // margin-left: auto;
                    padding-left: 100px;
                    margin-right: auto;
                    max-width: 1024px;
                    margin-bottom: 30px;
                    .header-tab{
                        display: flex;
                        flex-direction: row;
                        align-items: baseline;
                        .title{
                            font-size: 20px;
                            font-weight: 400;
                            text-decoration: underline;
                            text-decoration-color: $color-default;
                            padding-right: 20px;
                        }
                        .subtitle{
                            display: flex;
                            font-size: 14px;
                            font-weight: 300;
                        }
                    }
                }
                .tab2-section-2{
                    // margin-left: auto;
                    margin-right: auto;
                    max-width: 1024px;
                    padding-left: 100px;
                    height: 550px;
                    display: flex;
                    justify-content: left;
                    margin-bottom: 30px;
                    .ground-image{
                        width: 80%;
                        height: 100%;
                        background-color: #eaeaea;
                        border-radius: 10px;
                        // border: 3px ridge #000;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    .ground-images{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 20%;
                        height: 100%;
                        .sub-ground{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-left: 20px;
                            // width: 80%;
                            height: 30%;
                            background-color: #eaeaea;
                            border-radius: 10px;
                            &:last-child{
                                margin-bottom: 0px;
                            }
                        }
                    }
                    .image-gallery{
                        width: 100%;

                        .image-gallery-thumbnail{
                            width: unset;
                            margin-top: 20px;
                            .thumnail-container{
                                display: block;
                                position: relative;
                                width: 100px;
                                height: 80px;
    
                                .delete{
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                    position: absolute;
                                    top: -5px;
                                    right: -5px;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    border: none;
                                    background-color: red;
                                    color: white;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .image-gallery-thumbnail.active, 
                    .image-gallery-thumbnail:focus,
                    .image-gallery-thumbnail:hover
                    {
                        border: 4px solid $color-default;
                    }
                    .image-gallery-content .image-gallery-slide .image-gallery-image{
                        height: 50vh !important;
                        .item-img{
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .tab2-section-3{
                    // margin-left: auto;
                    margin-right: auto;
                    max-width: 1024px;
                    margin-bottom: 30px;
                    padding-left: 100px;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        text-decoration: underline;
                        text-decoration-color: $color-default;
                        padding-right: 20px;
                    }
                }
                .tab2-section-4{
                    background-color: $color-secondary;
                    text-align: center;
                    margin-bottom: 30px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    .text{
                        color: #5F5F5F;
                        font-size: 3.5em;
                        .text-inner{
                            color: transparent;
                            background-color: $color-default;
                            text-shadow: -4px 4px 6px rgba(255, 255, 255, 0.304);
                            font-size: 1em;
                            font-weight: 500;
                            -webkit-background-clip: text;
                            -moz-background-clip: text;
                            background-clip: text;
                        }
                    }
                }
                .tab2-section-5{
                    .contain-center{
                        display: flex;
                        justify-content: center;
                    }
                    .box{
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        border-radius: 10px;
                        width: 100%;
                        height: 100%;

                        .box-img{
                            width: 100%;
                            margin-bottom: 30px;
                        }
                        
                        .box-body{
                            display: flex;
                            flex-direction: column;
                            color: #000;

                            .title{
                                font-size: 20px;
                                font-weight: 500;
                            }
                            .sub-title{
                                font-size: 12px;
                                margin-bottom: 20px;
                            }
                            .box-content{
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                                margin-bottom: 30px;

                                >p{
                                    padding: 10px;

                                    &.patch{
                                        background-color: #F5F5F5;
                                    }
                                }

                                .box-price{
                                    margin-top: 80px;
                                    .text-1{
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                        width: 40%;
                                    }
                                    .text-2{
                                        display: flex;
                                        flex-direction: column;
                                        margin-left: 10px;
                                        width: 60%;
                                        margin-top: -25px;
                                        .text-sm{
                                            font-weight: 200;
                                            font-size: 8px;
    
                                            .price-dis{
                                                font-size: 14px;
                                                text-decoration: line-through;
                                                text-decoration-color: red;
                                            }
                                        }
                                        .text-bold{
                                            font-size: 2em;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: baseline;
                                        }
                                    }
                                    .content-text{
                                        display: flex;
                                        flex-direction: column;
                                        border-left: 3px solid rgb(255, 255, 255);
                                    }
                                }
                            }
                        }
                        .box-footer{
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                        }
                    }
                }

                //tab3
                .tab3-container{
                    background-color: $color-secondary;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    padding-bottom: 30px;
                    .tab3-column1{
                        width: 58%;
                        background-color: $color-white;
                        padding-left: 50px;
                        padding-right: 50px;
                        padding-top: 30px;

                        .header{
                            display: flex;
                            justify-content: center;
                            color: $color-default;
                            font-size: 20px;
                            font-weight: 400;
                            padding-bottom: 30px;
                        }
                        .title{
                            font-weight: 500;
                            margin-bottom: 20px;
                        }
                        .image-container{
                            display: flex;
                            justify-content: center;
                            width: 100%;
                            // height: 200px;

                            .image-gallery{
                                width: 100%;
                            }
                            .image-gallery-thumbnail.active, 
                            .image-gallery-thumbnail:focus,
                            .image-gallery-thumbnail:hover
                            {
                                border: 4px solid $color-default;
                            }
                            .image-gallery-content .image-gallery-slide .image-gallery-image{
                                height: 50vh !important;
                                .image-preview{
                                    width: 100%;
                                    height: 100%;
                                }
                                
                            }
                        }
                        .detail-containner{
                            .title{
                                font-weight: 400;
                                // padding-bottom: 10px;
                            }

                            .detail-wrapper{
                                padding-top: 20px;
                                padding-bottom: 30px;
                                display: flex;
                                flex-direction: row;
                                border-top: 0.5px solid $color-gray;
                                border-bottom: 0.5px solid $color-gray;
                                margin-bottom: 20px;
                                .profile-img{
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 50%;
                                    margin-right: 20px;
                                }
                                .d-type{
                                    font-size: 12px;
                                    width:fit-content;
                                    padding: 5px;
                                    border-radius: 5px;
                                    border: 0.5px solid $color-default;
                                    color: $color-default;
                                    background-color: $color-white;
                                    text-align: center;
                                }

                                .quick{
                                    font-size: 12px;
                                    width:fit-content;
                                    padding: 5px;
                                    border-radius: 5px;
                                    border: 0.5px solid $color-blue;
                                    color: $color-blue;
                                    background-color: $color-white;
                                    text-align: center;
                                }
                            }
                            .detail-wrapper2{
                                border: 0.5px solid $color-gray;
                                border-radius: 10px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .tab3-column2{
                        width: 40%;
                        background-color: $color-white;
                        // padding-left: 50px;
                        // padding-right: 50px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        .column-wrpper{
                            width: 70%;
                        }

                        .noti-box-container{
                            padding: 10px 20px;
                            background-color: #0e589e;
                            color: #ffffff;
                            border-radius: 5px;

                            ul{
                                padding-left: 20px;
                                li{
                                    list-style:disc;
                                }
                            }
                        }

                        .check-box-container{
                            padding: 10px 20px;
                            background-color: #CCE4FB;
                            color: #26A2F1;
                            border-radius: 5px;
                            cursor: pointer;
                            &.form-check{
                                padding-left: 2rem !important;
                            }
                            .form-check-input{
                                border-color: #CCE4FB !important;
                            }
                            .form-check-input:checked {
                                background-color: #26A2F1;
                                border-color: #26A2F1;
                            }
                        }
                    }
                }
            }
        }
        .btn-content{
            margin-bottom: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
    }

    .tab-contact{
        display: block;
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        height: 450px;
        background-image: url("../../../img/xd.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: left 20% center;

        .contact-wrapper{
            display: block;
            position: absolute;
            right: 10%;
            top: 35%;
            text-align: end;
            color: $color-default;
        }
    }
}

@media screen and (max-width: 991px) {
    .tab-content{
        .tab-wrapper{
            .tab-section-1{
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
            .tab-section-2{
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
            .tab-section-3{
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
            .tab-section-5{
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
            .tab-contact{
                padding-right: 10px !important;
            }
    
            .tab2-section-1{
                flex-direction: column !important;
                padding: 0px 10px !important;
                .header-tab{
                    flex-direction: column !important;
                }
            }
            .tab2-section-2{
                padding: 0px 10px !important;
                .ground-image{
                    width: 100% !important;
                }
                .ground-images{
                    display: none !important;
                }
            }
            .tab2-section-3{
                padding: 0px 10px !important;
            }
    
            .tab3-container{
                display: flex !important;
                flex-direction: column !important;
                .tab3-column1{
                    width: 100% !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    padding-bottom: 50px;
                }
                .tab3-column2
                {
                    width: 100% !important;
                }
            }
        }
        .tab-contact{
            .contact-wrapper{
                top: 10% !important;
                right: 20px !important;
            }
        }
    }
}