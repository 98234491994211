.dashboard-container{
    display: block;
    position: relative;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;

    .header{
        font-size: 30px;
        font-weight: 500;
    }

    .dashboard-profile{
        padding: 30px;
        border-radius: 10px;
        // display: flex;
        // flex-direction: column;
        width: 100%;
        background-color: white;

        .dashboard-profile-wrapper{
            display: flex;
            flex-direction: row;

            .profile-img{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-bottom: 30px;
                width: 40%;
                /* background-color: blue; */
                .image{
                    width: 150px;
                    height: 150px;
                    border-radius: 50%;
                }
            }
    
            .profile-detail{
                width: 60%;
            }
        }
    }

    .dashboard-status{
        .dashboard-status-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;
            border: 2px solid $color-default;
            border-radius: 10px;
        }
    }

    .dashboard-landingpage{
        .dashboard-landingpage-wrapper{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px;
            border: 2px solid $color-default;
            border-radius: 10px;
        }
    }
}

.setting-page{
    display: flex;
    justify-content: space-between;

    .setting-flex-1{
        width: 30%;
        padding: 10px;
        
        .setting-box{
            overflow-y: auto;
            padding: 10px;
            border-radius: 10px;
            background-color: rgb(238, 238, 238);
        }
    }
    .setting-flex-2{
        width: 70%;
        padding: 10px;

        .setting-dispaly{
            border: 0.5px solid #7d7d7d;
            .nav{
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 20px;
                padding: 10px;
            }
            .banner{
                width: 100%;
                height: 350px;
                position: relative;
                margin-bottom: 20px;
                .banner-img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }

                .text-title{
                    position: absolute;
                    text-align: center;
                    display: flex;
                    // justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    left: 0;
                    top: 50px;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    
                    .title{
                        font-size: 3.5vw;
                        font-weight: 500;
                        -webkit-text-stroke: 0.5px rgb(255, 255, 255);
                    }
                    .sub-title{
                        font-size: 1.5vw;
                        font-weight: 400;
                        -webkit-text-stroke: 0.5px rgb(255, 255, 255);
                    }
                }

                .property-box{
                    padding-left: 20px;
                    padding-right: 20px;
                    // border: 1px solid #000;
                    position: absolute;
                    bottom: 50px;
                    left: 0;
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 2;
                    
                    .property-wrapper{
                        width: 100%;
                        padding: 20px;
                        background-color: #ffffff;
                        display: flex;
                        border-radius: 10px;
                    }
                }
            }
            .page-property{
                margin-bottom: 50px;
                max-width: 1024px;
                margin-left: auto;
                margin-right: auto;
                padding: 10px;
    
                .property-item{
                    position: relative;
                    height: 150px; 
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 15px;
    
                    .label-1{
                        position: absolute;
                        top: 10px;
                        left: 10px;
                        font-size: 14px;
                        font-weight: 400;
                    }
    
                    .icon{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 10px;
                        right: 10px;
                        width: 25px;
                        height: 25px;
                        background-color: #ffffff;
                        border-radius: 50%;
                    }
    
                    .label-2{
                        position: absolute;
                        bottom: 10px;
                        right: 10px;
                        font-size: 14px;
                    }
    
                    .text-number{
                        font-weight: 500;
                        font-size: 2.5em;
                    }
                }
            }
            .page-body-1{
                padding: 10px;
                p.title{
                    font-size: 2.5em;
                    font-weight: 400;
                    text-align: center;
                }
                .card-tag{
                    display: block;
                    position: relative;
                    
                    .tag{
                        display: block;
                        position: absolute;
                        top: 10px;
            
                        .tag-1{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
            
                        .tag-2{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                        .tag-3{
                            display: block;
                            width: fit-content;
                            padding-left: 10px;
                            padding-right: 10px;
                        }
                    }
                }
                .card-body{
                    .body-1{
                        .card-title{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            height: 1.5em;
                        }
                        .card-text{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 4.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            .page-body-3{
                padding: 10px;
                p.title{
                    font-size: 2.5em;
                    font-weight: 400;
                    text-align: center;
                }
                .header{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
            
                    .user-img{
                        width: 64px;
                        height: 64px;
                        object-fit: cover;
                        border-radius: 50%;
                    }
            
                    .oversize{
                        font-size: 1.8rem;
                        font-weight: 500;
                        color: $color-default;
                    }
            
                    .text-wrapper{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: $color-default;
                        color: white;
                        width: 90%;
                        border-radius: 50px;
                        // width: fit-content;
            
                        .icon{
                            margin-left: -20px;
                        }
                        .label{
                            text-align: center;
                            line-height: 1.5em;
            
                            .label-1{
                                font-size: 1.3em;
                                font-weight: 400;
                            }
                        }
                    }
            
                    .date{
                        position: absolute;
                        top: 5px;
                        right: 5px;
                    }
                }
            }
            .page-body-4{
                display: flex;
                position: relative;
                padding: 3vw;
                margin-bottom: 50px;
                max-width: 1320px;
                margin-left: auto;
                margin-right: auto;
                background-color: #D5DBDB;
                border-radius: 20px;
                height: 100%;
    
                .box-1{
                    padding: 20px;
                    margin-right: 20px;
                    width: 40%;
                    // position: relative;
                    .subBox{
                        width: 300px;
                        position: relative;
                        .decorate-1{
                            position: absolute;
                            top: -10px;
                            left: -10px;
                            width: 60px;
                            height: 60px;
                            background-color: rgb(75, 75, 75);
                            border-radius: 10px;
                            z-index: 0;
                        }
                        .decorate-2{
                            position: absolute;
                            bottom: -5px;
                            right: -10px;
                            width: 100px;
                            height: 100px;
                            background-color: rgb(75, 75, 75);
                            border-radius: 10px;
                            z-index: 0;
                        }
        
                        .profile-img{
                            position: relative;
                            width: 100%;
                            border-radius: 10px;
                            z-index: 2;
        
                            // &::after{
                            //     display: block;
                            //     content: '';
                            //     position: absolute;
                            //     top: -10px;
                            //     left: -10px;
                            //     width: 40px;
                            //     height: 40px;
                            //     background-color: slateblue;
                            //     border-radius: 10px;
                            //     z-index: 1;
                            // }
                        }
                    }
    
                    .subBox-2{
                        display: flex;
                        // position: relative;
                        width: 100%;
                    }
                    
                }
                .box-2{
                    width: 60%;
                    margin-left: 20px;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
    
                    .subBox{
                        width: 400px;
                    }
                }
            }
        }
    }
}

.modal-salePin{
    .card-tag{
        display: block;
        position: relative;
        
        .tag{
            display: block;
            position: absolute;
            top: 10px;

            .tag-1{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }

            .tag-2{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }
            .tag-3{
                display: block;
                width: fit-content;
                padding-left: 10px;
                padding-right: 10px;
            }
        }
        .tag-pin{
            display: block;
            position: absolute;
            cursor: pointer;
            right: 10px;
            top: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: rgba(0, 0, 0, 0.373);
            display: flex;
            justify-content: center;
            align-items: center;
            .icon{
                color: rgb(186, 186, 186);

                &.pin{
                    color: red;
                }
            }
        }
    }
    .card-body{
        .body-1{
            .card-title{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 1.5em;
            }
            .card-text{
                overflow: hidden;
                text-overflow: ellipsis;
                height: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }
}

.sale-container{
    display: block;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
}

.sale-container 
.header{
    font-size: 30px;
    font-weight: 500;
}
.sale-container 
.sub-header{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sale-container
.sale-footer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.annonce-user-container{
    display: block;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
}
.annonce-user-container
.header{
    font-size: 30px;
    font-weight: 500;
}

.annonce-user-container
.card
.title-annonce{
    margin: 10px 0px 30px -10px;
}

.annonce-user-container
.card
.title-annonce
.label-1{
    margin-right: 10px;
    background-color: #EF9751;
    padding: 0px 10px;
    color: white;
}

.annonce-user-container
.card
.title-annonce
.label-2{
    color: #EF9751;
}

.annonce-user-container
.card
.title-annonce
small{
    padding: 0px 10px;
    color: #7d7d7d;
}

.annonce-user-container
.annonce-user-wrapper
.card
.text-description
.text-detail{
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 4.5em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.favorite-container
{
    display: block;
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
}

.favorite-container
.header{
    font-size: 30px;
    font-weight: 500;
}

.favorite-container
.card{
    display: block;
    .card-body{
        .body-1{
            .card-title{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 1.5em;
            }
            .card-text{
                overflow: hidden;
                text-overflow: ellipsis;
                height: 4.5em;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
            }
        }
    }

    .card-tag{
        position: relative;
        .tag{
            display: block;
            position: absolute;
            top: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .tag-2{
            display: block;
            position: absolute;
            top: 40px;
            padding-left: 10px;
            padding-right: 10px;
        }

        .btn-favorite{
            position: absolute;
            right: 10px;
            top: 10px;
            z-index: 5;
            .icon{
                font-size: 18px;
            }
        }
    }
}

.favorite-container
.favorite-footer{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;
}

@media screen and (max-width: 991px) {
    .dashboard-container{
        padding: 10px;

        .dashboard-profile{
            .dashboard-profile-wrapper{
                flex-direction: column;

                .profile-img{
                    width: 100%;
                }
                .profile-detail{
                    width: 100%;
                }
            }
        }
    }

    .sale-container{
        margin-left: 10px;
    }

    .annonce-user-container{
        margin-left: 10px;
    }
}
