.estate-container{
    display: block;
    max-width: 1024px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;

    .estate-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;

        .container-like{
            display: flex;
            flex-direction: row;
            align-items: center;
        }
    }

    .estate-picture{
        width: 100%;
        height: 100%;
    }
    .estate-info{
        display: flex;
        flex-direction: row;
        position: relative;
        .estate-info-container-1{
            display: flex;
            flex-direction: column;
            width: 60%;
            padding: 0px 20px 20px 0px;

            .header-container{
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                border-bottom: 0.5px solid $color-gray;
                padding-bottom: 10px;
                .header{
                    font-size: x-large;
                    font-weight: 400;
                }
                .saler-quick{
                    width: fit-content;
                    .description{
                        padding: 5px;
                        font-size: 14px;
                        color: $color-danger;
                        border: 0.5px solid $color-danger;
                        border-radius: 10px;
                    }
                }
            }

            .showinfo{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 30px;
                border-bottom: 0.5px solid $color-gray;
            }

            .co-agent{
                padding: 10px;
                background-color: rgb(210, 229, 252);
                color: rgb(52, 94, 151);
                border-radius: 10px;
            }

            .saler-info{
                display: flex;
                flex-direction: row;
                padding: 30px 0px;
                border-bottom: 0.5px solid $color-gray;
                .saler-img{
                    width: 64px;
                    height: 64px;
                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border-radius: 50%;
                    }
                }
                .saler-detail{
                    .saler-body > p{
                        line-height: 2em;
                        font-weight: 400;
                    }
                    .saler-status{
                        width: fit-content;
                        .description{
                            padding: 5px;
                            font-size: 14px;
                            color: $color-default;
                            border: 0.5px solid $color-default;
                            border-radius: 10px;
                        }
                    }

                    

                    .hideinfo{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            .estate-description{
                width: 100%;
                .col{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 20px;
                    .title{
                        font-size: large;
                        font-weight: 400;
                    }
                    .text{
                        padding: 10px 5px;
                        border-bottom: 0.5px solid $color-gray;
                    }
                }
                .estate-cityplan{
                    display: flex;
                    flex-direction: column;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                }
                .estate-highlight{
                    width: 100%;
                    // max-width: 70%;
                    display: flex;
                    flex-direction: column;
                    .title{
                        font-size: 20px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    .text{
                        padding: 10px;
                        border: 0.5px solid $color-gray;
                        border-radius: 10px;
                        resize: none;
                    }
                }
            } 
        }
        .estate-info-container-2{
            display: flex;
            flex-direction: column;
            width: 40%;
            padding: 0px 20px 20px 20px;
            .detail-wrapper{
                border: 0.5px solid $color-gray;
                border-radius: 10px;
            }
        }
    }

    .estate-map{
        width: 100%;
        .header{
            font-size: x-large;
            font-weight: 400;
            margin-bottom: 20px;
        }
        .mapWrapper{
            width: 100%;
            height: 60vh;
            border-radius: 10px;
        }

        .gm-style-iw{
            width: 330px !important;
            .image-gallery{
                height: 200px;
                border-radius: 10px;
                .item-img{
                    width: 300px;
                    height: 200px;
                    border-radius: 10px;
                    object-fit: contain;
                    overflow: hidden;
                }
                .image-gallery-left-nav,
                .image-gallery-right-nav{
                    padding: 0px 8px !important;
                    .gallery-icon-size{
                        font-size: 20px;
                    }
                }
            }
        }

        .gm-style-iw-d{
            overflow-x: hidden !important;
        }
    }

    .estate-near{
        width: 100%;
        .header{
            font-size: x-large;
            font-weight: 400;
            margin-bottom: 20px;
        }
        .card{
            display: block;
            height: 100%;
            .card-body{
                .body-1{
                    .card-title{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        height: 1.5em;
                    }
                    .card-text{
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 4.5em;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }

        .tag{
            display: block;
            position: absolute;
            top: 10px;
            padding-left: 10px;
            padding-right: 10px;
        }
        .tag-2{
            display: block;
            position: absolute;
            top: 40px;
            padding-left: 10px;
            padding-right: 10px;
        }

    }
}

@media screen and (max-width: 991px) {
    .estate-container{
        padding-left: 10px;
        padding-right: 10px;

        .estate-info{
            display: flex;
            flex-direction: column;
            .estate-info-container-1{
                width: 100%;
                padding: 10px;
            }
            .estate-info-container-2{
                width: 100%;
                padding: 10px;
            }
        }
    }
}