.content-container{
    display: block;
    padding-left: 10px;
    padding-right: 10px;
    .content-img{
        width: 100%;
        border-radius: 10px;
    }

    .content-highlight{
        // padding-left: 50px;
        // padding-right: 50px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;

        .content-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title{
                font-size: 25px;
                font-weight: 400;
            }
        }

        .card{
            .card-body{
                .card-title{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 3.5em;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .card-text{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .card-footer{
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 20px;
    
                .tags{
                    display: flex;
                    align-items: center;
                    padding: 2px 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                    border-radius: 20px;
                    background-color: $color-sky;
                }
            }
        }

        .content-highlight-footer{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    .content-video{
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;

        .content-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title{
                font-size: 25px;
                font-weight: 400;
            }
        }

        .card{
            .card-body{
                .card-title{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 3.5em;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                .card-text{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .card-footer{
                display: flex;
                flex-direction: column;
                padding-left: 10px;
                padding-right: 10px;
                padding-bottom: 20px;
    
                .tags{
                    display: flex;
                    align-items: center;
                    padding: 2px 10px;
                    margin-left: 5px;
                    margin-right: 5px;
                    border-radius: 20px;
                    background-color: $color-sky;
                }
            }
        }

        .content-video-footer{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }

    .content-tiktok{
        margin-left: auto;
        margin-right: auto;
        max-width: 1024px;

        .content-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .title{
                font-size: 25px;
                font-weight: 400;
            }
        }
        .content-tiktok-footer{
            display: flex;
            flex-direction: row;
            justify-content: center;
        }
    }
}

.content-view-contianer{
    display: block;
    margin-top: 30px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    min-height: 100vh;
        
    .header{
        font-weight: 500;
        padding-bottom: 10px;
    }

    .content-view-wrapper{
        display: flex;
        flex-direction: row;
        
        .content-view-body{
            margin: 5px;
            width: 71%;
            min-height: 1000px;
            padding-right: 10px;
            word-break: break-all;
            
            .content-view-block p{
                font-size: 16px;
            }

            ol{
                padding-left: 50px;
            }
            ul{
                padding-left: 50px;
            }

            img{
                width: 100%;
            }
            iframe{
                width: 100%;
                height: 50vh;
            }
        }

        .content-view-property{
            // position: relative;
            width: 29%;

            .content-view-block{
                position: sticky;
                position: -webkit-sticky;
                top: 100px;
                margin: 5px;
                padding-left: 10px;

                

                .btn-share-wrapper{
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    p{
                        font-weight: 500;
                        font-size: 20px;
                    }
                }

                .more-content-wrapper{
                    width: 100%;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    p{
                        text-align: center;
                        font-weight: 500;
                        font-size: 20px;
                    }

                    .highlight-content{
                        display: flex;
                        flex-direction: row;
                        cursor: pointer;
                        img{
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            margin-right: 10px;
                        }
                        .text{
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 1.5;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            height: 4.5em;
                            display: -webkit-box;
                            -webkit-line-clamp: 3;
                            -webkit-box-orient: vertical;
                        }

                        &:hover{
                            background-color: $color-secondary;
                        }
                    }
                }
            }
        }
    }

    .content-tags{
        display: flex;
        flex-direction: row;

        .tags{
            align-items: center;
            padding: 2px 10px;
            margin-left: 5px;
            margin-right: 5px;
            border-radius: 20px;
            background-color: $color-sky;
            color: white;
        }
    }

    .content-sign{
        border-top: 1px solid $color-secondary;
        border-bottom: 1px solid $color-secondary;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .title{
            font-size: 25px;
            font-weight: 500;
        }
    }
}

@media screen and (max-width: 991px) {
    .content-container{
        .content-highlight{
            .content-header{
                flex-direction: column;

                .title{
                    font-size: x-large;
                }

                .content-header-right{
                    justify-content: end;
                }
            }
        }

        .content-video{
            .content-header{
                flex-direction: column;

                .title{
                    font-size: x-large;
                }

                .content-header-right{
                    justify-content: end;
                }
            }
        }
    }

    .content-view-contianer{
        .content-view-wrapper{
            flex-direction: column;

            .content-view-body{
                width: 100%;
            }
            .content-view-property{
                width: 100%;
            }
        }
    }
}