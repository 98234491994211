.packages-container{
    .packages-banner-wrapper{
        position: relative;
        .packages-banner-img{
            height: 400px;
            width: 100%;
            background-image: url('../../../img/Package/packagenew.jpeg');
            background-position: left top;
            background-repeat: no-repeat;
            background-size: cover;
        }
        .packages-banner-text{
            position: absolute;
            top: 20%;
            left: 20%;
            width: 45%;
        }
    }
    .packages-body{
        // position: relative;
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;

        table td[scope="row"]{
            position: sticky;
            left: 0;
            z-index: 999;
        }
    }
}

@media screen and (max-width: 991px){
    .packages-container{
        .packages-banner-wrapper{
            .packages-banner-text{
                top: 20%;
                left: 0;
                padding-left: 10px;
                width: 100%;
            }
        }
        .packages-body{
            // overflow-x: auto;
            padding-left: 10px;
        }
    }
}