
.service-container{
    .banner-package{
        width: 100%;
        height: 100%;
        object-fit: contain;
        margin-bottom: 50px;
    }

    .package-nav{
        display: flex;
        flex-direction: row;
        // justify-content: space-between;
        max-width: 1124px;
        margin-left: auto;
        margin-right: auto;

        .nav-item{
            display: flex;
            justify-content: center;
            flex: 1 1 auto;
            // width: 35%;
            font-size: x-large;
            color: $color-gray;
            border-bottom: 1px solid $color-default;
            border-left: 1px solid white;
            border-top: 1px solid white;
            border-right: 1px solid white;
            cursor: pointer;
            transition: 0.5s all ease-in-out;

            &.active{
                border-left: 1px solid $color-default;
                border-top: 1px solid $color-default;
                border-right: 1px solid $color-default;
                border-bottom: 1px solid white;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: $color-default;
            }
        }
    }

    .show-package{
        .provide-container{
            display: block;
            width: 100%;
            padding-bottom: 50px;

            .text{
                font-size: 20px;
                max-width: 1024px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 50px;
            }
            .bg-img{
                display: block;
                position: relative;
                // align-items: center;
                padding-top: 50px;
                height: 450px;

                .contianer-ads{
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    align-items: center;
                    max-width: 1024px;
                    height: 100%;
                    margin-left: auto;
                    margin-right: auto;
                    text-align: center;
                    z-index: 2;

                    .text-ads{
                        color: $color-default;
                        font-weight: 500;
                        font-size: 40px;
                    }
                    
                }
            }
            .bg-img::before{
                content: '';
                display: block;
                position: absolute;
                background-image: url('../../../img//Package/provide/portrait-from-back-tired.png');
                background-repeat: no-repeat;
                background-position: bottom center;
                background-size: cover;
                left: 0;
                top: 0;
                width: 100%;
                height: 450px;
                opacity: 0.2;
                z-index: 1;
            }
            
            .example-list{
                display: block;
                position: relative;
                max-width: 1024px;
                padding: 10px;
                margin-top: -200px;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                z-index: 3;
            }

            .provide-footer{
                display: flex;
                position: relative;
                flex-direction: row;
                justify-content: center;
            }
        }
        .consignment-container{
            max-width: 1024px;
            // border: 0.5px solid gray;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;

            .text{
                font-size: 20px;
            }

            .consignment-footer{
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
        .package-container{
            max-width: 1024px;
            // border: 0.5px solid gray;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
        
            .max-width-600{
                max-width: 600px;
            }

            .tab{
                display: none;

                &.active{
                    display: block;
                }
            }
        
            .tab .container-left{
                display: flex;
                flex-direction: row;
                padding: 30px 50px;
                margin-bottom: 50px;
                background-color: $color-white;
        
                .ads-img{
                    width: 250px;
                    box-shadow: $color-white 7px 7px 0px -2px, $color-default 7px 7px;
                    border-radius: 10px;
                    margin-right: 80px;
                }
        
                .txt-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 40%;
                }
            }
        
            .tab .container-right{
                display: flex;
                flex-direction: row-reverse;
                padding: 30px 50px;
                margin-bottom: 50px;
                background-color: $color-secondary;
        
                .ads-img{
                    width: 250px;
                    box-shadow: $color-secondary 7px 7px 0px -2px, $color-default 7px 7px;
                    border-radius: 10px;
                    margin-left: 80px;
                }
        
                .txt-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // align-items: flex-start;
                    width: 40%;
                }
            }
        }

        .morepackage-container{
            max-width: 1024px;
            // border: 0.5px solid gray;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
        
            .max-width-600{
                max-width: 600px;
            }
        
            .container-left{
                display: flex;
                flex-direction: row;
                padding: 30px 50px;
                margin-bottom: 50px;
                background-color: $color-white;
        
                .ads-img{
                    width: 250px;
                    box-shadow: $color-white 7px 7px 0px -2px, $color-default 7px 7px;
                    border-radius: 10px;
                    margin-right: 80px;
                }
        
                .txt-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 40%;
                }
            }
        
            .container-right{
                display: flex;
                flex-direction: row-reverse;
                padding: 30px 50px;
                margin-bottom: 50px;
                background-color: $color-secondary;
        
                .ads-img{
                    width: 250px;
                    box-shadow: $color-secondary 7px 7px 0px -2px, $color-default 7px 7px;
                    border-radius: 10px;
                    margin-left: 80px;
                }
        
                .txt-container{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    // align-items: flex-start;
                    width: 40%;
                }
            }
        }
    }

    .package-wrapper{
        padding-left: 50px;
        padding-right: 50px;

        .title{
            font-size: 2rem;
            font-weight: 500;
            text-align: center;
        }
        .sub-title{
            font-size: 1rem;
            text-align: center;
        }

        .package-choose{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 100px;
            .text-choose{
                cursor: pointer;
                margin-left: 30px;
                margin-right: 30px;
                font-size: x-large;
                font-weight: 400;
                color: $color-gray;
                border-bottom: 2px solid $color-white;
        
                &.active{
                    color: $color-default;
                    border-bottom: 2px solid $color-default;
                }
            }
        }
    }

    .calculator-container{
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
        .container{
            background-color: $color-secondary;
            border-radius: 8px;
            padding: 10px;

            .addrows{
                display: flex;
                justify-content: center;
            }
        }
        .platform-container{
            padding: 5px;

            .platform-wrapper{
                .title{
                    display: flex;
                    justify-content: center;
                    position: relative;
                    text-align: center;
                    margin-bottom: -30px;
                    width: 100%;
                    z-index: 1;
                }
                .text-wrapper{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 30px;
                    border-radius: 10px;
                    .ios{
                        display: flex;
                        text-align: left;
                        color: white;
                        align-items: center;
                        font-weight: 500;
                        font-size: 1.2rem;
                        cursor: pointer;
                    }
                    .android{
                        display: flex;
                        text-align: end;
                        color: white;
                        align-items: center;
                        font-weight: 500;
                        font-size: 1.2rem;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {
    .service-container{
        .banner-img{
            margin-bottom: 20px;
        }
        .package-choose{
            flex-direction: column;

            .text-choose{
                margin-bottom: 10px;
            }
        }
        .package-nav{
            max-width: none;
            .nav-item{
                font-size: 12px;
            }
        }
        .show-package{
            .consignment-container{
                padding: 10px;
            }

            .morepackage-container{
                padding: 10px;
                .max-width-600{
                    max-width: 600px;
                }
                .container-left{
                    padding: 0px 0px;
                    flex-direction: column;
                    align-items: center;
                    .ads-img{
                        margin-right: 0px;
                        margin-bottom: 30px;
                        box-shadow: none;
                    }
                    .txt-container{
                        width: 100%;
                        align-items: center;
                    }
                }
                .container-right{
                    padding: 0px 0px;
                    flex-direction: column;
                    align-items: center;
                    background-color: $color-white;
                    .ads-img{
                        margin-left: 0px;
                        margin-bottom: 30px;
                        box-shadow: none;
                    }
                    .txt-container{
                        width: 100%;
                        align-items: center;
                    }
                }
            }
        }
        .package-wrapper{
            .title{
                font-size: 1rem;
            }

            .package-choose{
                .text-choose{
                    margin-left: 10px;
                    margin-right: 10px;
                    font-size: small;
                    font-weight: 400;
                }
            }

            .package-container{
                .max-width-600{
                    max-width: 600px;
                }
                .tab .container-left{
                    padding: 0px 0px;
                    flex-direction: column;
                    align-items: center;
                    .ads-img{
                        margin-right: 0px;
                        margin-bottom: 30px;
                        box-shadow: none;
                    }
                    .txt-container{
                        width: 100%;
                        align-items: center;
                    }
                }
                .tab .container-right{
                    padding: 0px 0px;
                    flex-direction: column;
                    align-items: center;
                    background-color: $color-white;
                    .ads-img{
                        margin-left: 0px;
                        margin-bottom: 30px;
                        box-shadow: none;
                    }
                    .txt-container{
                        width: 100%;
                        align-items: center;
                    }
                }
            }
        }
    }
}